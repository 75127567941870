import { Outlet, useParams } from "react-router-dom";
import { useAppSelector } from "../store/configureStore";
import NotFound from "../../features/errors/NotFound";

export default function RequireParam() {
    const { user } = useAppSelector((state) => state.account);
    const { restaurantParam } = useParams<{ restaurantParam: string }>();

    // If the user doesn't own the restaurant
    if (!user!.restaurants!.some( (element) => element.restaurantName.toLowerCase() === restaurantParam?.toLowerCase())) {
        console.error("Error: No matching restaurant found.");
        return <NotFound />;
    }
    else
        return <Outlet />;
}
