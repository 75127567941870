import { Container, Typography, Divider, Grid } from "@mui/material";
import PermIdentityIcon from "@mui/icons-material/PermIdentity";
import { useAppSelector } from "../../../app/store/configureStore";
import { primaryColor } from "../../../app/style/customColor";

export default function Profile() {
    const { user } = useAppSelector((state) => state.account);

    return (
        <Container>
            <Grid container marginTop={3}>
                <PermIdentityIcon fontSize="large" color="primary" />
                <Typography
                    variant="h5"
                    fontWeight="bold"
                    color={primaryColor}
                    sx={{ marginLeft: 1 }}
                >
                    Profile
                </Typography>
            </Grid>
            <Divider />
            <Typography variant="h6" fontWeight="bold" marginTop={3}>
                User Name
            </Typography>
            <Typography variant="body1">{user!.userName}</Typography>
            <Divider />
            <Typography variant="h6" fontWeight="bold" marginTop={3}>
                User Email
            </Typography>
            <Typography variant="body1">{user!.email}</Typography>
        </Container>
    );
}
