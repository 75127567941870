import { Grid, Paper, Typography } from "@mui/material";

export default function TechnologyPage() {
    const pngFileName = [
        {
            title: "React",
            fileName: "react.png",
            tooltip: "Google",
        },
        {
            title: ".NET core",
            fileName: "dotnetcore.png",
            tooltip: "Microsoft",
        },
        {
            title: "Axios",
            fileName: "axios.png",
            tooltip: "Axios",
        },
        {
            title: "Azure",
            fileName: "Azure.png",
            tooltip: "Microsoft",
        },
        {
            title: "Signal-R",
            fileName: "signalR-logo.png",
            tooltip: "Microsoft",
        },
        {
            title: "Material UI",
            fileName: "material-ui.png",
            tooltip: "Google",
        },
    ];

    return (
        <Grid container padding={0}>
            <Grid item xs={12}>
                <Typography
                    color="white"
                    fontWeight="700"
                    variant="h5"
                    sx={{
                        textAlign: "center",
                        marginBottom: { xs: "1.5rem", md: "1.5rem" },
                    }}
                >
                    We are using most up-to-date technology
                </Typography>
            </Grid>

            <Grid item xs={12}>
                <Grid container sx={{}}>
                    {pngFileName.map((i) => (
                        <ImageLogoGrid
                            key={i.title}
                            filename={i.fileName}
                            title={i.title}
                            tooltip={i.tooltip}
                        />
                    ))}
                </Grid>

                <Grid item xs={12}>
                    <Paper sx={{ padding: "2rem", borderRadius: 0 }}>
                        <Typography variant="h6" fontWeight={700} gutterBottom>
                            Discover The Cutting-Edge Waitlist Management
                            Solution!
                        </Typography>
                        <br />
                        <Typography variant="body1">
                            Our innovative waitlist management app integrates a
                            powerful tech stack:{" "}
                            <strong>
                                React, .NET, Axios, Azure, SignalR, and Material
                                UI
                            </strong>
                            .
                        </Typography>
                        <br />
                        <Typography variant="body1">
                            With a React front-end, QueuingMe offers a
                            user-friendly interface for easy waitlist checks.
                            Azure's robust cloud service enables real-time
                            updates and efficient admin control, supported by
                            .NET and SignalR.
                        </Typography>
                        <br />
                        <Typography variant="body1">
                            Axios and SignalR streamline communication and data
                            synchronization, allowing quick waitlist status
                            monitoring. Material UI adds modern design elegance
                            to the application.
                        </Typography>
                    </Paper>
                </Grid>
            </Grid>
        </Grid>
    );
}

interface Props {
    title: string;
    filename: string;
    tooltip: string;
}

function ImageLogoGrid({ title, filename, tooltip }: Props) {
    return (
        <Grid item xs={4} md={2}>
            <Paper
                sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    minHeight: "150px",
                    padding: {
                        xs: "1rem",
                        sm: "1rem 1rem",
                    },
                    margin: {
                        xs: 0,
                        md: "0rem 0rem",
                    },
                    boxShadow: "0",
                    borderRadius: 0,
                }}
            >
                <img
                    src={`/images/mainpage/Tech/${filename}`}
                    style={{
                        display: "block",
                        minHeight: "100px",
                        minWidth: "100px",
                        maxHeight: "150px",
                        maxWidth: "300px",
                    }}
                    title={`${title} - ${tooltip}`}
                    alt="logo"
                />
            </Paper>
        </Grid>
    );
}
