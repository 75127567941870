import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { useEffect, useState } from "react";
import { Pie } from "react-chartjs-2";
import { useParams } from "react-router-dom";
import agent from "../../../app/api/agent";
import { QueueStatusObj } from "../../../app/models/stat";
import { Container, Skeleton, Typography } from "@mui/material";

ChartJS.register(ArcElement, Tooltip, Legend);

const options = {
    plugins: {
        title: {
            display: true,
            text: "Status from Total Queues",
        },
    },
    responsive: true,
};

export default function StatusStat() {
    const { restaurantParam } = useParams<{ restaurantParam: string }>();
    const [data, setData] = useState<any>(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        var newLabels: string[] = [];
        var newQueues: number[] = [];

        agent.Statistics.getQueueStatusCounts(restaurantParam!)
            .then((queues: QueueStatusObj[]) => {
                queues.map((queue: QueueStatusObj) => {
                    newLabels.push(queue.status);

                    newQueues.push(queue.queueCount);
                });
            })
            .then(() => {
                setData({
                    labels: newLabels,
                    datasets: [
                        {
                            label: "Status from Total Queues",
                            data: newQueues,
                            backgroundColor: [
                                "rgba(255, 99, 132, 0.2)",
                                "rgba(54, 162, 235, 0.2)",
                                "rgba(255, 206, 86, 0.2)",
                                // 'rgba(75, 192, 192, 0.2)',
                                // 'rgba(153, 102, 255, 0.2)',
                                // 'rgba(255, 159, 64, 0.2)',
                            ],
                        },
                    ],
                });
            })
            .finally(() => setLoading(false));
    }, []);

    return (
        <Container sx={{ height: "100%" }}>
            {loading ? (
                <>
                    <Skeleton
                        variant="text"
                        width="20%"
                        sx={{
                            fontSize: "1rem",
                            display: "block",
                            margin: "auto",
                        }}
                    />
                    <Skeleton
                        variant="text"
                        width="40%"
                        sx={{
                            fontSize: "1rem",
                            display: "block",
                            margin: "auto",
                        }}
                    />
                    <br />
                    <Skeleton
                        variant="rounded"
                        width="90%"
                        height={500}
                        sx={{ display: "block", margin: "auto" }}
                    />
                </>
            ) : (
                <Pie data={data} options={options} />
            )}
            <br />

            <Typography variant="subtitle2" sx={{ textAlign: "center" }}>
                * To ensure accurate data, please click the 'no-show' button if
                the customer didn't actually show up.
            </Typography>
        </Container>
    );
}
