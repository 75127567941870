import { Grid, Paper, Typography } from "@mui/material";

interface ListContextProps {
    title: string;
    context: string;
}

export default function ListContext({
    mainTitle,
    aboutArray,
}: {
    mainTitle: string;
    aboutArray: ListContextProps[];
}) {
    const listStyle = {
        paddingLeft: "1rem",
    };

    const boldStyle = {
        fontWeight: "bold",
    };

    return (
        <Paper
            sx={{
                padding: { xs: "0.5rem", md: "1rem" },
                marginTop: "5rem",
                boxShadow: 0,
                background: `rgba(255, 255, 255, 0) url('/images/QueuingMeLogoWhite.png') no-repeat right bottom`,
                backgroundSize: "30vh",
            }}
        >
            <Typography
                gutterBottom
                sx={{
                    fontWeight: "700",
                    fontSize: { xs: "1.2rem", sm: "1.5rem" },
                    textRendering: "optimizeLegibility",
                    padding: {md: 3}
                }}
            >
                {mainTitle}
            </Typography>
            <Grid container>
                {aboutArray.length >= 2
                    ? aboutArray.map(({ title, context }) => (
                          <Grid item xs={12} md={6} key={title}  sx={{padding: {md: 3}}}>
                              <Typography
                                  variant="body1"
                                  component="div"
                                  sx={{ fontSize: {xs: "1rem", sm: "1.2rem" }}}
                              >
                                  <ul style={listStyle}>
                                      <li style={boldStyle}>{title}</li>
                                      <ul>
                                          <li style={{marginLeft: "-20px",}}
                                              dangerouslySetInnerHTML={{
                                                  __html: context,
                                              }}
                                          />
                                      </ul>
                                      <br />
                                  </ul>
                              </Typography>
                          </Grid>
                      ))
                    : aboutArray.map(({ title, context }) => (
                          <Grid item xs={12} key={title}>
                              <Typography
                                  variant="body1"
                                  component="div"
                                  sx={{ fontSize: {xs: "1rem", sm: "1.2rem" }}}
                              >
                                  <ul style={listStyle}>
                                      <li style={boldStyle}>{title}</li>
                                      <ul>
                                          <li
                                              dangerouslySetInnerHTML={{
                                                  __html: context,
                                              }}
                                          />
                                      </ul>
                                      <br />
                                  </ul>
                              </Typography>
                          </Grid>
                      ))}
            </Grid>
        </Paper>
    );
}
