import React from 'react';

const restaurantDisplay: { [key: string]: string } = {
    "otreat": "Otreat Brunch + Bistro",
    "makchangdodookaustin": "Makchang Dodook Austin",
}

const RestaurantNameDisplay: React.FC<string> = ( restaurantName ) => {
    const restaurantNameLower = restaurantName.toLowerCase();
    const displayedName = restaurantDisplay[restaurantNameLower] || restaurantName;

    return <span>{displayedName}</span>;
}

export default RestaurantNameDisplay;