import React, { useState } from "react";
import AppBar from "@mui/material/AppBar";
import Container from "@mui/material/Container";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import MenuIcon from "@mui/icons-material/Menu";
import Button from "@mui/material/Button";
import MenuItem from "@mui/material/MenuItem";
import LoginIcon from "@mui/icons-material/Login";
import SendIcon from "@mui/icons-material/Send";
import { Box } from "@mui/material";
import { homeColor } from "../../style/customColor";
import { useNavigate } from "react-router-dom";

interface HomeHeaderProps {
    handleButtonClick: (page: string) => void;
}

const pages: string[] = ["Company", "Solution", "Technology", "Demo"];

export default function HomeHeader({ handleButtonClick }: HomeHeaderProps) {
    const navigate = useNavigate();
    const [anchorElNav, setAnchorElNav] = useState<null | HTMLElement>(null);

    const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorElNav(event.currentTarget);
    };

    const handleCloseNavMenu = () => {
        setAnchorElNav(null);
    };

    const handleMobileMenuItemClick = (page: string) => {
        handleCloseNavMenu();
        setTimeout(() => handleButtonClick(page), 0);
    };

    return (
        <AppBar
            position="sticky"
            sx={{ bgcolor: homeColor.background_cobaltBlue }}
            elevation={0}
        >
            <Container maxWidth="xl">
                <Toolbar disableGutters>
                    <img
                        src={`/images/QueuingMeLogoWhite.png`}
                        style={{
                            display: "block",
                            maxHeight: "40px",
                            maxWidth: "100px",
                            marginRight: "15px",
                        }}
                        alt="logo"
                    />

                    {/* <Typography
                        variant="h5"
                        noWrap
                        component="a"
                        href="/"
                        sx={{
                            mr: 2,
                            display: { xs: "flex", md: "none" },
                            flexGrow: 1,
                            fontFamily: "-apple-system",
                            fontWeight: 700,
                            color: "inherit",
                            textDecoration: "none",
                        }}
                    >
                        QueuingMe
                    </Typography> */}
                    <Box
                        sx={{
                            flexGrow: 1,
                            display: { xs: "none", md: "flex" },
                            justifyContent: "space-between",
                        }}
                    >
                        <Container sx={{ display: "flex" }}>
                            {pages.map((page) => (
                                <Button
                                    key={page}
                                    onClick={() => handleButtonClick(page)}
                                    sx={{
                                        my: 2,
                                        color: "white",
                                        display: "block",
                                    }}
                                >
                                    {page}
                                </Button>
                            ))}
                        </Container>

                        <Button
                            variant="contained"
                            startIcon={<SendIcon />}
                            color="secondary"
                            sx={{
                                width: "14rem",
                                margin: "auto",
                                fontWeight: 700,
                            }}
                        >
                            Free Demo&nbsp;
                        </Button>
                        <Button
                            variant="contained"
                            startIcon={<LoginIcon />}
                            sx={{
                                width: "10rem",
                                margin: "auto 2rem",
                                fontWeight: 700,
                            }}
                            onClick={() => navigate(`/login`)}
                        >
                            Login
                        </Button>
                    </Box>
                    <Box
                        sx={{
                            flexGrow: 1,
                            display: {
                                xs: "flex",
                                md: "none",
                                justifyContent: "flex-end",
                            },
                        }}
                    >
                        <Button
                            key="demo"
                            variant="contained"
                            size="small"
                            color="secondary"
                            startIcon={<SendIcon />}
                            sx={{
                                margin: "auto 0.5rem",
                                height: "2rem",
                                paddingTop: 0,
                                paddingBottom: 0,
                                fontWeight: 700,
                                fontSize: "0.8rem",
                            }}
                            onClick={() => handleButtonClick(pages[3])}
                        >
                            <Typography
                                variant="inherit"
                                sx={{
                                    display: { xs: "none", sm: "block" },
                                    fontWeight: "inherit",
                                    fontSize: "inherit",
                                    lineHeight: "inherit",
                                }}
                            >
                                Free Demo
                            </Typography>
                        </Button>

                        <Button
                            variant="contained"
                            size="small"
                            startIcon={<LoginIcon />}
                            sx={{
                                margin: "auto 1rem",
                                height: "2rem",
                                paddingTop: 0,
                                paddingBottom: 0,
                            }}
                            onClick={() => navigate(`/login`)}
                        >
                            Login
                        </Button>
                        <IconButton
                            size="large"
                            aria-label="account of current user"
                            aria-controls="menu-appbar"
                            aria-haspopup="true"
                            onClick={handleOpenNavMenu}
                            color="inherit"
                        >
                            <MenuIcon />
                        </IconButton>
                        <Menu
                            id="menu-appbar"
                            anchorEl={anchorElNav}
                            anchorOrigin={{
                                vertical: "bottom",
                                horizontal: "right",
                            }}
                            keepMounted
                            transformOrigin={{
                                vertical: "top",
                                horizontal: "right",
                            }}
                            open={Boolean(anchorElNav)}
                            onClose={handleCloseNavMenu}
                            sx={{
                                display: { xs: "block", md: "none" },
                            }}
                        >
                            {pages.map((page) => (
                                <MenuItem
                                    key={page}
                                    onClick={() =>
                                        handleMobileMenuItemClick(page)
                                    }
                                >
                                    <Typography textAlign="center">
                                        {page}
                                    </Typography>
                                </MenuItem>
                            ))}
                        </Menu>
                    </Box>
                </Toolbar>
            </Container>
        </AppBar>
    );
}
