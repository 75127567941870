import { Button, Fade, Menu, MenuItem } from "@mui/material";
import React from "react";
import { useAppDispatch, useAppSelector } from "../store/configureStore";
import { signOut } from "../../features/account/accountSlice";
import SettingsIcon from "@mui/icons-material/Settings";
import { NavLink, useParams } from "react-router-dom";

export default function SignedInMenu() {
    const dispatch = useAppDispatch();
    const { restaurantParam } = useParams<{ restaurantParam: string }>();
    const { user } = useAppSelector((state) => state.account);
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <>
            <Button
                color="inherit"
                onClick={handleClick}
                sx={{ typography: "h6" }}
                endIcon={<SettingsIcon />}
            ></Button>
            <Menu
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                TransitionComponent={Fade}
            >
                <MenuItem onClick={handleClose}>
                    <Button
                        component={NavLink}
                        to={`/${restaurantParam}/ProfilePage`}
                    >
                        {user?.userName}
                    </Button>
                </MenuItem>
                <MenuItem onClick={handleClose}>
                    {user &&
                        user?.restaurants &&
                        user?.restaurants?.length >= 2 && (
                            <Button
                                component={NavLink}
                                to={`/${restaurantParam}/displayAll`}
                            >
                                Check All Queues
                            </Button>
                        )}
                </MenuItem>
                <MenuItem onClick={handleClose}>
                    <Button
                        onClick={() => {
                            dispatch(signOut());
                        }}
                    >
                        Logout
                    </Button>
                </MenuItem>
            </Menu>
        </>
    );
}
