import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { useEffect, useState } from "react";
import displayStatus, { Queue, Status } from "../../app/models/queue";
import agent from "../../app/api/agent";
import { Box, Button, Typography } from "@mui/material";
import {
    StyledDashBoardTableCell,
    StyledDashBoardTableRow,
    dashBoardStatusFontSize,
} from "../../app/style/customStyle";
import LoadingComponent from "../../app/layout/LoadingComponent";
import { useParams } from "react-router-dom";
import { restaurant } from "../../app/configuration/restaurant";
import NotFound from "../errors/NotFound";
import { displayPhoneNumber } from "../../app/configuration/phoneNumberConvertor";
import QRcodeMargin from "../../app/layout/partComponents/QRcodeMargin";

export default function DisplayPage() {
    const [queues, setQueues] = useState<Queue[]>([]);
    const { restaurantParam } = useParams<{ restaurantParam: string }>();
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        agent.Display.list(restaurantParam!).then((queues) => {
            setQueues(queues);
            setLoading(false);
        });
    }, []); //eslint-disable-line

    useEffect(() => {
        const intervalCall = setInterval(() => {
            agent.Display.list(restaurantParam!).then((queues) => {
                setQueues(queues);
            });
        }, 5000);
        return () => {
            clearInterval(intervalCall);
        };
    }, []); //eslint-disable-line

    if (loading) return <LoadingComponent message="Loading..." />;

    if (!restaurant.includes(restaurantParam!.toLowerCase()))
        return <NotFound />;

    return (
        <div style={{ display: "block", margin: "40px" }}>
            <Box display="flex" alignItems="center" justifyContent="center">
                <img
                    src={`/images/${restaurantParam}Icon.png`}
                    style={{
                        maxHeight: "90px",
                        padding: "5px",
                        maxWidth: "15%",
                        margin: "5px",
                    }}
                    alt="logo"
                />
                <Typography variant="h4" fontWeight="900">
                    Waiting List
                </Typography>
            </Box>

            <TableContainer component={Paper}>
                <Table sx={{ width: "100%" }} aria-label="a simple table">
                    <TableHead>
                        <TableRow>
                            <StyledDashBoardTableCell>
                                Queue
                            </StyledDashBoardTableCell>
                            <StyledDashBoardTableCell>
                                Name
                            </StyledDashBoardTableCell>
                            <StyledDashBoardTableCell>
                                Phone Number
                            </StyledDashBoardTableCell>
                            <StyledDashBoardTableCell>
                                Size
                            </StyledDashBoardTableCell>
                            <StyledDashBoardTableCell>
                                Status
                            </StyledDashBoardTableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {queues.map((row, idx) => (
                            <StyledDashBoardTableRow
                                key={row.id.toString()}
                                sx={{
                                    "&:last-child td, &:last-child th": {
                                        border: 0,
                                    },
                                }}
                            >
                                <StyledDashBoardTableCell
                                    component="th"
                                    scope="row"
                                >
                                    {idx + 1}
                                </StyledDashBoardTableCell>
                                <StyledDashBoardTableCell>
                                    {row.userName.split(" ")[0]}
                                </StyledDashBoardTableCell>
                                <StyledDashBoardTableCell>
                                    {displayPhoneNumber(row.phoneNumber)}
                                </StyledDashBoardTableCell>
                                <StyledDashBoardTableCell>
                                    {row.size}{" "}
                                    {(row.sizeKids != 0 ||
                                        row.sizeKids == null) &&
                                        " + " + row.sizeKids}
                                </StyledDashBoardTableCell>
                                <StyledDashBoardTableCell>
                                    <Button
                                        variant={
                                            row.status === Status.Waiting
                                                ? "outlined"
                                                : "contained"
                                        }
                                        color={
                                            row.status === Status.Ready
                                                ? "success"
                                                : "primary"
                                        }
                                        sx={{
                                            minWidth: "200px",
                                            fontSize: dashBoardStatusFontSize,
                                        }}
                                    >
                                        {displayStatus(row.status)}
                                    </Button>
                                </StyledDashBoardTableCell>
                            </StyledDashBoardTableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <QRcodeMargin restaurantName={restaurantParam!} />
        </div>
    );
}
