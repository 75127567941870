import axios, { AxiosError, AxiosResponse } from "axios";
import { store } from "../store/configureStore";
import { toast } from "react-toastify";

axios.defaults.baseURL = process.env.REACT_APP_API_URL;

const responseBody = (response: AxiosResponse) => response.data;

axios.interceptors.request.use((config) => {
    const token = store.getState().account.user?.token;
    if (token) config.headers.Authorization = `Bearer ${token}`;
    return config;
});

axios.interceptors.response.use(
    (response) => {
        return response;
    },
    (error: AxiosError) => {
        const { data, status } = error.response as AxiosResponse;
        switch (status) {
            case 400:
                if (data.errors) {
                    const modelStateErrors: string[] = [];
                    for (const key in data.errors) {
                        if (data.errors[key]) {
                            modelStateErrors.push(data.errors[key]);
                        }
                    }
                    throw modelStateErrors.flat();
                }
                toast.error(data.title);
                console.log("400 Error");
                break;
            case 401:
                toast.error(data.title);
                break;
            case 500:
                // router.navigate(`/server-error`, {
                //     state: { error: data },
                // });
                toast.error(data.title);
                break;
            default:
                break;
        }
        return Promise.reject(error.response);
    }
);

const requests = {
    get: (url: string) => axios.get(url).then(responseBody),
    post: (url: string, body: {}) => axios.post(url, body).then(responseBody),
    put: (url: string, body: {}) => axios.put(url, body).then(responseBody),
    delete: (url: string) => axios.delete(url).then(responseBody),
};

const Reservation = {
    list: (restaurantParam: string) =>
        requests.get(`queues/${restaurantParam}/getAllReservations`),
};

const Display = {
    list: (restaurantParam: string) =>
        requests.get(`queues/${restaurantParam}/getAllQueues`),
    listAllRestaurants: () => requests.get("queues/GetAllActiveQueues"),
    details: (id: string, restaurantParam: string) =>
        requests.get(`queues/${restaurantParam}/${id}`),
    // getMessage: (id: string) => requests.get(`queuess/getMessage/${id}`),
    create: (values: any) =>
        requests.post(`queues/${values.restaurantName}/createQueue`, values),
    updateStatusByAdmin: (values: any) =>
        requests.put(
            `queues/${values.restaurantName}/updateStatusByAdmin/${values.id}`,
            values
        ),
    updateStatusByGuest: (values: any) =>
        requests.put(
            `queues/${values.restaurantName}/updateStatusByGuest/${values.id}`,
            values
        ),
    updateQueue: (values: any) =>
        requests.put(`queues/${values.restaurantName}/${values.id}`, values),
    getArchived: (restaurantParam: string) =>
        requests.get(`queues/${restaurantParam}/getArchivedQueues`),
};

const Account = {
    login: (values: any) => requests.post("account/login", values),
    register: (values: any) => requests.post("account/register", values),
    currentUser: () => requests.get("account/currentUser"),
};

const Message = {
    sendInvitationMessage: (values: any) =>
        requests.post(`message/SendInvitationMessage`, values),
};

const Statistics = {
    getWeeklyQueues: (values: any) => {
        return requests.post(
            `statistics/${values.restaurantName}/getWeeklyQueues`,
            values
        );
    },
    getQueueStatusCounts: (restaurantParam: string) =>
        requests.get(`statistics/${restaurantParam}/getQueueStatusCounts`),
    getWeeklyQueuesSize: (values: any) => {
        return requests.post(
            `statistics/${values.restaurantName}/getWeeklyQueuesSize`,
            values
        );
    },
    getWeeklyWaitingTime: (values: any) => {
        return requests.post(
            `statistics/${values.restaurantName}/getWeeklyWaitingTime`,
            values
        );
    },
};

const TestErrors = {
    get400Error: () => requests.get("error/bad-request"),
    get401Error: () => requests.get("error/unauthorized"),
    get404Error: () => requests.get("error/not-found"),
    get500Error: () => requests.get("error/server-error"),
    getValidationError: () => requests.get("error/validation-error"),
};

const PasswordReset = {
    requestReset: (values: any) =>
        requests.post("account/change-password", values),
    resetPassword: (values: any) =>
        requests.post("account/reset-password", values),
};

const agent = {
    Display,
    Account,
    TestErrors,
    Reservation,
    Message,
    Statistics,
    PasswordReset,
};

export default agent;
