import { Typography } from "@mui/material";
import { guestMsg } from "../configuration/guestMsg";
import { Queue, Status } from "../models/queue";
import { primaryColor, successColor } from "../style/customColor";

export function displayGuestDetailTitle(queue: Queue) {
    let msg = "";
    switch (queue.status) {
        case Status.Waiting:
            msg = guestMsg.GuestDetailMessage.title.waiting;
            break;
        case Status.Notified:
            msg = guestMsg.GuestDetailMessage.title.notified;
            break;
        case Status.Ready:
            msg = guestMsg.GuestDetailMessage.title.ready;
            break;
        case Status.Cancel:
            msg = guestMsg.GuestDetailMessage.title.cancel;
            break;
        case Status.Complete:
            msg = guestMsg.GuestDetailMessage.title.complete;
            break;
        case Status.NoShow:
            msg = guestMsg.GuestDetailMessage.title.noshow;
            break;

        default:
            break;
    }
    return (
        <Typography
            variant="h5"
            fontWeight="bold"
            color={
                queue.status === Status.Waiting ? primaryColor : successColor
            }
        >
            {msg}{" "}
            {queue.position! > 0 && queue.status === Status.Waiting
                ? "#" + queue.position
                : ""}
        </Typography>
    );
}
