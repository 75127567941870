import { useEffect, useState } from "react";
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import agent from "../../../app/api/agent";
import { useParams } from "react-router-dom";
import { formatDateYYYYMMDD } from "../../../app/extentions/WaitingTime";
import { Container, Skeleton, Typography } from "@mui/material";

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
);

const options = {
    responsive: true,
    plugins: {
        legend: {
            position: "top" as const,
        },
        title: {
            display: true,
            text: "Waiting Time",
        },
    },
};

const dateLabels = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
];

export default function WaitTimeStat() {
    const { restaurantParam } = useParams<{ restaurantParam: string }>();
    const [datasets, setDatasets] = useState<any>(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchData = async () => {
            const today = new Date();
            const lastWeekDay = new Date(today);
            lastWeekDay.setDate(lastWeekDay.getDate() - 7);
            const thisWeekParam = {
                datetime: formatDateYYYYMMDD(today),
                restaurantName: restaurantParam,
            };
            const lastWeekParam = {
                datetime: formatDateYYYYMMDD(lastWeekDay),
                restaurantName: restaurantParam,
            };
            const data1 = await agent.Statistics.getWeeklyWaitingTime(
                lastWeekParam
            );
            const data2 = await agent.Statistics.getWeeklyWaitingTime(
                thisWeekParam
            );

            const lastWeekData = data1.map((item: any) => item.item2);
            const thisWeekData = data2.map((item: any) => item.item2);

            setDatasets([
                {
                    label: "Last week",
                    data: lastWeekData,
                    backgroundColor: "rgba(255, 99, 132, 0.5)",
                },
                {
                    label: "This week",
                    data: thisWeekData,
                    backgroundColor: "rgba(53, 162, 235, 0.5)",
                },
            ]);
        };
        fetchData();
        setLoading(false);
    }, [restaurantParam]);

    if (!datasets) return null;

    const data = {
        labels: dateLabels,
        datasets: datasets,
    };

    return (
        <Container sx={{ height: "100%" }}>
            {loading ? (
                <>
                    <Skeleton
                        variant="text"
                        width="20%"
                        sx={{
                            fontSize: "1rem",
                            display: "block",
                            margin: "auto",
                        }}
                    />
                    <Skeleton
                        variant="text"
                        width="40%"
                        sx={{
                            fontSize: "1rem",
                            display: "block",
                            margin: "auto",
                        }}
                    />
                    <br />
                    <Skeleton
                        variant="rounded"
                        width="90%"
                        height={500}
                        sx={{ display: "block", margin: "auto" }}
                    />
                </>
            ) : (
                <Bar options={options} data={data} />
            )}
            <br />

            <Typography variant="subtitle2" sx={{ textAlign: "center" }}>
                * To ensure accurate data, please click the 'no-show' button if
                the customer didn't actually show up.
            </Typography>
        </Container>
    );
}
