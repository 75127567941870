import { Navigate, Outlet, useParams } from "react-router-dom";
import { useAppSelector } from "../store/configureStore";
import NotFound from "../../features/errors/NotFound";

interface Props {
    roles?: string[];
}

export default function RequireAuth({ roles }: Props) {
    const { user } = useAppSelector((state) => state.account);
    const { restaurantParam } = useParams<{ restaurantParam: string }>();

    if (!user) {
        return <Navigate to={`/${restaurantParam}/login`} />;
    }

    // If the user doesn't own the restaurant
    if (!user.restaurants!.some( (element) => element.restaurantName.toLowerCase() === restaurantParam?.toLowerCase())) {
        console.error("Error: No matching restaurant found.");
        return <NotFound />;
    }
    else {
        if (roles && !roles.some((r) => user.roles?.includes(r))) {
            return <Navigate to={`/${restaurantParam}/admin`} />;
        }
        return <Outlet />;
    }
}
