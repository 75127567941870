import { useEffect, useState } from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import PhoneIcon from "@mui/icons-material/Phone";
import { toast } from "react-toastify";
import agent from "../../app/api/agent";
import displayStatus, { Queue, Status } from "../../app/models/queue";
import {
    Box,
    Button,
    Link,
    Paper,
    Table,
    TableBody,
    TableContainer,
    TableHead,
    TableRow,
    Tooltip,
} from "@mui/material";
import * as signalR from "@microsoft/signalr";
import LoadingComponent from "../../app/layout/LoadingComponent";
import LensIcon from "@mui/icons-material/Lens";
import { red, green, orange, grey, blue } from "@mui/material/colors";
import { useAppSelector } from "../../app/store/configureStore";
import { StyledTableCell, StyledTableRow } from "../../app/style/customStyle";
import DisplayActiveQueuesModal from "./DisplayActiveQueuesModal";

interface QueueIconsProps {
    length: number;
}

interface RestaurantQueuesDto {
    userId: string;
    restaurantName: string;
    phoneNumber: string;
    queues: Queue[];
}

const getColor = (length: number) => {
    if (length == 0) return grey[500];
    else if (length < 5) return green[500];
    else if (length < 10) return orange[500];
    else return red[500];
};

const QueueIcons: React.FC<QueueIconsProps> = ({ length }) => {
    const color = getColor(length);

    return (
        <Box
            sx={{
                display: "flex",
                alignItems: "center",
                color: "text.secondary",
            }}
            mr={3}
        >
            <LensIcon sx={{ color }} />
        </Box>
    );
};

const DisplayActiveQueues = () => {
    const { user } = useAppSelector((state) => state.account);
    const [restaurantQueues, setRestaurantQueues] = useState<
        RestaurantQueuesDto[]
    >([]);
    const [loading, setLoading] = useState(true);
    const [notification, setNotification] = useState("");
    const [notIncludedRestaurants, setNotIncludedRestaurants] = useState<
        RestaurantQueuesDto[]
    >([]);

    const [selectedQueue, setSelectedQueue] = useState<Queue | null>(null);
    const [selectedRestaurantName, setSelectedRestaurantName] = useState("");

    const handleRowClick = (queue: Queue, restaurantName: string) => {
        setSelectedQueue(queue);
        setSelectedRestaurantName(restaurantName);
    };

    const handleCloseModal = () => {
        setSelectedQueue(null);
        setSelectedRestaurantName("");
    };

    useEffect(() => {
        agent.Display.listAllRestaurants()
            .then((response) => {
                setRestaurantQueues(response);
                setLoading(false);
            })
            .catch((error) => {
                toast.error("Failed to load active queues");
                setLoading(false);
            });
        // signalR connection
        const connection = new signalR.HubConnectionBuilder()
            .configureLogging(signalR.LogLevel.None)
            .withUrl("/DbUpdateHub", {
                skipNegotiation: true,
                transport: signalR.HttpTransportType.WebSockets,
            })
            .build();

        connection.start();

        connection.on("ReceiveNotification", (rest, msg) => {
            setNotification(msg);
        });
    }, []); //eslint-disable-line

    useEffect(() => {
        agent.Display.listAllRestaurants().then((response) => {
            setRestaurantQueues(response);
        });
    }, [notification]); //eslint-disable-line

    useEffect(() => {
        const noWaitingRestaurants = user!.restaurants!.filter((restaurant) => {
            return !restaurantQueues.some(
                (rq) => rq.restaurantName === restaurant.restaurantName
            );
        });
        setNotIncludedRestaurants(noWaitingRestaurants);
    }, [restaurantQueues]);

    useEffect(() => {
        const interval = setInterval(() => {
            agent.Display.listAllRestaurants().then((response) => {
                setRestaurantQueues(response);
            });
        }, 1800000); // 30 minutes

        return () => clearInterval(interval);
    }, []);

    if (!user || loading) return <LoadingComponent message="Loading..." />;

    return (
        <Box>
            {restaurantQueues.map((restaurant) => (
                <Accordion key={restaurant.restaurantName} defaultExpanded>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls={`panel-${restaurant.userId}-content`}
                        id={`panel-${restaurant.userId}-header`}
                    >
                        <Box
                            sx={{
                                display: "flex",
                                justifyContent: "space-between",
                                width: "100%",
                                alignItems: "center",
                            }}
                        >
                            <Typography>{restaurant.restaurantName}</Typography>

                            <Box
                                sx={{
                                    display: "flex",
                                    alignItems: "center",
                                    color: "text.secondary",
                                }}
                            >
                                <Link
                                    color="inherit"
                                    href={"tel:" + restaurant.phoneNumber}
                                >
                                    <PhoneIcon
                                        sx={{ mr: 3, color: blue[500] }}
                                    />
                                </Link>
                                <Tooltip title="# of waiting. Green: 5-, Orange: 10-, Red: 10+">
                                    <Box>
                                        <QueueIcons
                                            length={restaurant.queues.length}
                                        />
                                    </Box>
                                </Tooltip>
                            </Box>
                        </Box>
                    </AccordionSummary>
                    <AccordionDetails>
                        <TableContainer component={Paper}>
                            <Table size="small" aria-label="a simple table">
                                <TableHead>
                                    <TableRow>
                                        <StyledTableCell
                                            align="center"
                                            component="th"
                                        ></StyledTableCell>
                                        <StyledTableCell
                                            align="center"
                                            component="th"
                                        >
                                            Name
                                        </StyledTableCell>
                                        <StyledTableCell
                                            align="center"
                                            component="th"
                                        >
                                            Size
                                        </StyledTableCell>
                                        <StyledTableCell
                                            align="center"
                                            component="th"
                                        >
                                            Status
                                        </StyledTableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {restaurant.queues.map((queue, idx) => (
                                        <StyledTableRow
                                            key={idx}
                                            onClick={() =>
                                                handleRowClick(
                                                    queue,
                                                    restaurant.restaurantName
                                                )
                                            }
                                        >
                                            <StyledTableCell
                                                align="center"
                                                scope="row"
                                                component="td"
                                                sx={{
                                                    width: {
                                                        xs: "30px",
                                                        md: "150px",
                                                    },
                                                }}
                                            >
                                                {idx + 1}
                                            </StyledTableCell>
                                            <StyledTableCell
                                                align="center"
                                                scope="row"
                                                component="td"
                                            >
                                                {queue.userName}
                                            </StyledTableCell>
                                            <StyledTableCell
                                                align="center"
                                                scope="row"
                                                component="td"
                                                sx={{
                                                    width: {
                                                        xs: "80px",
                                                        md: "150px",
                                                    },
                                                }}
                                            >
                                                {queue.size + queue.sizeKids}
                                            </StyledTableCell>
                                            <StyledTableCell
                                                align="center"
                                                scope="row"
                                                component="td"
                                                sx={{
                                                    width: {
                                                        xs: "30px",
                                                        md: "150px",
                                                    },
                                                }}
                                            >
                                                <Button
                                                    variant={
                                                        queue.status ===
                                                        Status.Waiting
                                                            ? "outlined"
                                                            : "contained"
                                                    }
                                                    color={
                                                        queue.status ===
                                                        Status.Ready
                                                            ? "success"
                                                            : "primary"
                                                    }
                                                    sx={{
                                                        width: {
                                                            xs: "80px",
                                                            md: "120px",
                                                        },
                                                    }}
                                                >
                                                    {displayStatus(
                                                        queue.status
                                                    )}
                                                </Button>
                                            </StyledTableCell>
                                        </StyledTableRow>
                                    ))}
                                </TableBody>
                                {selectedQueue && (
                                    <DisplayActiveQueuesModal
                                        open={true} // Open the modal when selectedQueue is not null
                                        onClose={handleCloseModal}
                                        queue={selectedQueue}
                                        restaurantName={selectedRestaurantName} // Pass restaurant name to the modal
                                    />
                                )}
                            </Table>
                        </TableContainer>
                    </AccordionDetails>
                </Accordion>
            ))}

            {notIncludedRestaurants.length > 0 &&
                notIncludedRestaurants.map((restaurant) => (
                    <Accordion key={restaurant.restaurantName} defaultExpanded>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls={`panel-${restaurant.userId}-content`}
                            id={`panel-${restaurant.userId}-header`}
                        >
                            <Box
                                sx={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    width: "100%",
                                    alignItems: "center",
                                }}
                            >
                                <Typography>
                                    {restaurant.restaurantName}
                                </Typography>

                                <Box
                                    sx={{
                                        display: "flex",
                                        alignItems: "center",
                                        color: "text.secondary",
                                    }}
                                >
                                    <Link
                                        color="inherit"
                                        href={"tel:" + restaurant.phoneNumber}
                                    >
                                        <PhoneIcon
                                            sx={{ mr: 3, color: blue[500] }}
                                        />
                                    </Link>
                                    <Tooltip title="No waiting">
                                        <Box>
                                            <QueueIcons length={0} />
                                        </Box>
                                    </Tooltip>
                                </Box>
                            </Box>
                        </AccordionSummary>
                    </Accordion>
                ))}
            <Typography variant="caption" sx={{ display: { xs: "none", md: "block" }, padding: 2 }}>
                * This is optimized for mobile view
            </Typography>
        </Box>
    );
};

export default DisplayActiveQueues;
