import {
    Container,
    Divider,
    Grid,
    List,
    ListItem,
    ListItemButton,
    ListItemText,
    Paper,
} from "@mui/material";
import PasswordIcon from "@mui/icons-material/Password";
import RestaurantIcon from "@mui/icons-material/Restaurant";
import PermIdentityIcon from "@mui/icons-material/PermIdentity";
import SupportAgentIcon from "@mui/icons-material/SupportAgent";
import TroubleshootIcon from '@mui/icons-material/Troubleshoot';
import AutoStoriesIcon from "@mui/icons-material/AutoStories";
import { useState } from "react";
import PasswordResetForm from "./AccountSetting/PasswordResetForm";
import Profile from "./AccountSetting/Profile";
import RestaurantInfo from "./AccountSetting/RestaurantInfo";
import Application from "./AccountSetting/Application";
import Support from "./AccountSetting/Support";
import TroubleShoot from "./AccountSetting/TroubleShoot";

export default function ProfilePage() {
    const [selectedComponent, setSelectedComponent] = useState("Profile");
    const [selectedIndex, setSelectedIndex] = useState(1);

    const handleItemClick = (componentName: string, index: number) => {
        setSelectedComponent(componentName);
        setSelectedIndex(index);
    };

    const renderComponent = () => {
        switch (selectedComponent) {
            case "Profile":
                return <Profile />;
            case "Password Change":
                return <PasswordResetForm />;
            case "RestaurantInfo":
                return <RestaurantInfo />;
            case "Application":
                return <Application />;
            case "Trouble Shoot":
                return <TroubleShoot />;
            case "Support":
                return <Support />;
            default:
                return <Profile />;
        }
    };

    return (
        <Paper>
            <Container sx={{ p: 2, minHeight: "600px" }}>
                <Grid container spacing={2}>
                    <Grid item xs={2} sm={3}>
                        <nav aria-label="main mailbox folders">
                            <List>
                                <ListItem
                                    disablePadding
                                    onClick={() => handleItemClick("Profile", 1)}
                                >
                                    <ListItemButton
                                        selected={selectedIndex === 1}
                                    >
                                        <PermIdentityIcon />
                                        <ListItemText
                                            primary="Profile"
                                            sx={{
                                                display: {
                                                    xs: "none",
                                                    sm: "block",
                                                },
                                                marginLeft: 1,
                                            }}
                                        />
                                    </ListItemButton>
                                </ListItem>
                                <ListItem
                                    disablePadding
                                    onClick={() => handleItemClick("Password Change", 2)}
                                >
                                    <ListItemButton
                                        selected={selectedIndex === 2}
                                    >
                                        <PasswordIcon />
                                        <ListItemText
                                            primary="Password Change"
                                            sx={{
                                                display: {
                                                    xs: "none",
                                                    sm: "block",
                                                },
                                                marginLeft: 1,
                                            }}
                                        />
                                    </ListItemButton>
                                </ListItem>
                                <ListItem
                                    disablePadding
                                    onClick={() => handleItemClick("RestaurantInfo", 3)}
                                >
                                    <ListItemButton
                                        selected={selectedIndex === 3}
                                    >
                                        <RestaurantIcon />
                                        <ListItemText
                                            primary="Restaurant"
                                            sx={{
                                                display: {
                                                    xs: "none",
                                                    sm: "block",
                                                },
                                                marginLeft: 1,
                                            }}
                                        />
                                    </ListItemButton>
                                </ListItem>
                                <Divider />
                                <ListItem
                                    disablePadding
                                    onClick={() =>
                                        handleItemClick("Application", 4)
                                    }
                                >
                                    <ListItemButton
                                        selected={selectedIndex === 4}
                                    >
                                        <AutoStoriesIcon />
                                        <ListItemText
                                            primary="Application"
                                            sx={{
                                                display: {
                                                    xs: "none",
                                                    sm: "block",
                                                },
                                                marginLeft: 1,
                                            }}
                                        />
                                    </ListItemButton>
                                </ListItem>
                                <ListItem
                                    disablePadding
                                    onClick={() =>
                                        handleItemClick("Trouble Shoot", 5)
                                    }
                                >
                                    <ListItemButton
                                        selected={selectedIndex === 5}
                                    >
                                        <TroubleshootIcon />
                                        <ListItemText
                                            primary="Trouble Shoot"
                                            sx={{
                                                display: {
                                                    xs: "none",
                                                    sm: "block",
                                                },
                                                marginLeft: 1,
                                            }}
                                        />
                                    </ListItemButton>
                                </ListItem>
                                <ListItem
                                    disablePadding
                                    onClick={() =>
                                        handleItemClick("Support", 6)
                                    }
                                >
                                    <ListItemButton
                                        selected={selectedIndex === 6}
                                    >
                                        <SupportAgentIcon />
                                        <ListItemText
                                            primary="Support"
                                            sx={{
                                                display: {
                                                    xs: "none",
                                                    sm: "block",
                                                },
                                                marginLeft: 1,
                                            }}
                                        />
                                    </ListItemButton>
                                </ListItem>
                            </List>
                        </nav>
                    </Grid>
                    <Grid item xs={10} sm={9}>
                        {renderComponent()}
                    </Grid>
                </Grid>
            </Container>
        </Paper>
    );
}