import { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import agent from "../../app/api/agent";
import {
    GetWaitingTime,
    ReadyTimeCalculator,
    getLocaleTime,
} from "../../app/extentions/WaitingTime";
import displayStatus, { Queue, Status } from "../../app/models/queue";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableBody from "@mui/material/TableBody";
import TableRow from "@mui/material/TableRow";
import {
    StyledTableCell,
    StyledTableRow,
    adminTableButtonColumnWidth,
    adminTableCheckInColumnWidth,
    adminTableFirstColumnWidth,
    adminTableFontSize,
} from "../../app/style/customStyle";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import {
    Button,
    Tooltip,
    Badge,
    Typography,
    Grid,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
} from "@mui/material";
import OutdoorGrillIcon from "@mui/icons-material/OutdoorGrill";
import EditIcon from "@mui/icons-material/Edit";
import RefreshSharpIcon from "@mui/icons-material/RefreshSharp";
import AlarmOnIcon from "@mui/icons-material/AlarmOn";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import PersonOffIcon from "@mui/icons-material/PersonOff";
import DeleteIcon from "@mui/icons-material/Delete";
import ClearIcon from "@mui/icons-material/Clear";
import { customLightBlue, primaryColor } from "../../app/style/customColor";
import {
    configMiliTime,
    configTime,
    waitingWarningTime,
} from "../../app/configuration/configTime";
import LoadingComponent from "../../app/layout/LoadingComponent";
import { displayPhoneNumber } from "../../app/configuration/phoneNumberConvertor";
import { Guid } from "guid-typescript";
import { BBQrestaurants, restaurant } from "../../app/configuration/restaurant";
import * as signalR from "@microsoft/signalr";
import ArchiveDialogTable from "./ArchiveDialogTable";

interface SetIdProps {
    row: Queue | null;
    setOpen: boolean;
}

export default function AdminPage() {
    const WAIT_TIME = configMiliTime.refreshAdmin;
    const { restaurantParam } = useParams<{ restaurantParam: string }>();
    const navigate = useNavigate();
    const [queues, setQueues] = useState<Queue[]>([]);
    const [updateQueue, setUpdateQueue] = useState(false);
    const [notification, setNotification] = useState("");
    const [loading, setLoading] = useState(true);
    const [refresh, setRefresh] = useState(true);
    const [open, setOpen] = useState<SetIdProps>({ row: null, setOpen: false });
    const [updatedId, setUpdatedId] = useState<Guid | null>(null);

    useEffect(() => {
        agent.Display.list(restaurantParam!).then((queues) => {
            setQueues(queues);
        });
    }, [updateQueue, refresh]); //eslint-disable-line

    useEffect(() => {
        const id = setInterval(() => {
            agent.Display.list(restaurantParam!)
                .then((queues) => {
                    setQueues(queues);
                })
                .catch((err) => {
                    console.log(err.response);
                });
        }, WAIT_TIME);

        setLoading(false);
        return () => clearInterval(id);
    }, [queues]); //eslint-disable-line

    useEffect(() => {
        // signalR connection
        const connection = new signalR.HubConnectionBuilder()
            .configureLogging(signalR.LogLevel.None)
            .withUrl("/DbUpdateHub", {
                skipNegotiation: true,
                transport: signalR.HttpTransportType.WebSockets,
            })
            .build();

        connection.start();

        connection.on("ReceiveNotification", (rest, msg) => {
            if (rest.toLowerCase() === restaurantParam?.toLowerCase())
                setNotification(msg);
        });
    }, []); //eslint-disable-line

    useEffect(() => {
        agent.Display.list(restaurantParam!).then((queues) => {
            setQueues(queues);
        });
    }, [notification]); //eslint-disable-line

    function refreshPage() {
        setRefresh(!refresh);
    }

    function updateStatus(id: Guid, status: Status): void {
        agent.Display.updateStatusByAdmin({
            id: id,
            status: status,
            restaurantName: restaurantParam,
        }).then(() => {
            setUpdateQueue(!updateQueue);
        });
    }

    const handleClickOpen = (row: Queue) => {
        setOpen({ row: row, setOpen: true });
    };

    // Confirm dialog when X button is clicked.
    const handleAgree = (id: Guid, status: Status): void => {
        //axios request here
        updateStatus(id, status);
        setOpen({ row: null, setOpen: false });
    };

    if (loading) return <LoadingComponent message="Loading..." />;

    return (
        <TableContainer component={Paper}>
            <Table size="small" aria-label="a simple table">
                <TableHead>
                    <TableRow>
                        <StyledTableCell
                            align="center"
                            width={adminTableFirstColumnWidth}
                        >
                            <Button
                                fullWidth
                                variant="contained"
                                onClick={refreshPage}
                            >
                                <RefreshSharpIcon />
                            </Button>
                        </StyledTableCell>
                        <StyledTableCell
                            align="center"
                            sx={{
                                fontSize: adminTableFontSize,
                                fontWeight: "bold",
                            }}
                        >
                            Name
                            <br />
                            Phone
                        </StyledTableCell>
                        <StyledTableCell
                            align="center"
                            sx={{
                                fontSize: adminTableFontSize,
                                fontWeight: "bold",
                            }}
                        ></StyledTableCell>
                        <StyledTableCell
                            align="center"
                            sx={{
                                fontSize: adminTableFontSize,
                                fontWeight: "bold",
                            }}
                        >
                            Size
                        </StyledTableCell>
                        <StyledTableCell
                            align="center"
                            sx={{
                                fontSize: adminTableFontSize,
                                fontWeight: "bold",
                            }}
                        >
                            Kids
                        </StyledTableCell>

                        {BBQrestaurants.includes(
                            restaurantParam!.toLowerCase()
                        ) && (
                            <>
                                <StyledTableCell
                                    align="center"
                                    sx={{
                                        fontSize: adminTableFontSize,
                                        fontWeight: "bold",
                                    }}
                                >
                                    BBQ
                                </StyledTableCell>
                            </>
                        )}
                        <StyledTableCell
                            align="center"
                            sx={{
                                minWidth: adminTableCheckInColumnWidth,
                                fontSize: adminTableFontSize,
                                fontWeight: "bold",
                            }}
                        >
                            Checked-in
                        </StyledTableCell>
                        <StyledTableCell
                            align="center"
                            sx={{
                                fontSize: adminTableFontSize,
                                fontWeight: "bold",
                                maxWidth: "200px",
                            }}
                        >
                            Status
                            <br />
                            Note
                        </StyledTableCell>
                        <StyledTableCell
                            align="center"
                            width={adminTableButtonColumnWidth}
                            sx={{
                                fontSize: adminTableFontSize,
                                fontWeight: "bold",
                            }}
                        >
                            <Button
                                variant="contained"
                                onClick={() => {
                                    navigate(`/${restaurantParam}/guest`);
                                }}
                            >
                                <AddCircleOutlineIcon />
                                <b> Add</b>
                            </Button>
                        </StyledTableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {queues.map((row, idx) => (
                        <StyledTableRow key={row.id.toString()}>
                            <StyledTableCell
                                align="center"
                                sx={{ fontSize: adminTableFontSize }}
                                component="th"
                                scope="row"
                            >
                                {idx + 1}
                            </StyledTableCell>
                            <StyledTableCell
                                align="center"
                                sx={{ fontSize: adminTableFontSize }}
                            >
                                <Typography variant="subtitle2">
                                    {row.userName}
                                </Typography>
                                {displayPhoneNumber(row.phoneNumber)}
                            </StyledTableCell>
                            <StyledTableCell
                                align="center"
                                sx={{ fontSize: adminTableFontSize }}
                            >
                                <Tooltip title={row.id.toString()}>
                                    <Button
                                        sx={{ padding: 0, margin: -4 }}
                                        onClick={() => {
                                            navigate(
                                                `/${restaurantParam}/guest/${row.id}`
                                            );
                                        }}
                                    >
                                        <EditIcon fontSize="medium" />
                                    </Button>
                                </Tooltip>
                            </StyledTableCell>
                            <StyledTableCell
                                align="center"
                                sx={{ fontSize: adminTableFontSize }}
                            >
                                {row.size}
                            </StyledTableCell>
                            <StyledTableCell
                                align="center"
                                sx={{ fontSize: adminTableFontSize }}
                            >
                                {row.sizeKids}
                            </StyledTableCell>
                            {BBQrestaurants.includes(
                                restaurantParam!.toLowerCase()
                            ) && (
                                <>
                                    <StyledTableCell
                                        align="center"
                                        sx={{ fontSize: adminTableFontSize }}
                                    >
                                        {row.isBbq && <OutdoorGrillIcon />}
                                    </StyledTableCell>
                                </>
                            )}
                            <StyledTableCell
                                align="center"
                                sx={{
                                    fontSize: adminTableFontSize,
                                    margin: "auto",
                                }}
                            >
                                <Grid
                                    container
                                    rowSpacing={1}
                                    direction="column"
                                    justifyContent="center"
                                    alignItems="center"
                                >
                                    <Grid item xs={6}>
                                        <Badge
                                            badgeContent={
                                                GetWaitingTime(
                                                    row.createdTime
                                                ) + "m"
                                            }
                                            color={
                                                GetWaitingTime(
                                                    row.createdTime
                                                ) >= waitingWarningTime
                                                    ? "warning"
                                                    : "success"
                                            }
                                            showZero
                                            max={999}
                                        >
                                            <Button
                                                variant="outlined"
                                                color={
                                                    GetWaitingTime(
                                                        row.createdTime
                                                    ) >= waitingWarningTime
                                                        ? "warning"
                                                        : "success"
                                                }
                                            >
                                                {getLocaleTime(row.createdTime)}
                                            </Button>
                                        </Badge>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Tooltip title={Status[Status.Cancel]}>
                                            <Button
                                                variant="outlined"
                                                color="error"
                                                size="small"
                                                startIcon={<DeleteIcon />}
                                                onClick={() =>
                                                    updateStatus(
                                                        row.id,
                                                        Status.Cancel
                                                    )
                                                }
                                            >
                                                Cancel
                                            </Button>
                                        </Tooltip>
                                    </Grid>
                                </Grid>
                            </StyledTableCell>
                            <StyledTableCell
                                align="center"
                                sx={{
                                    fontSize: adminTableFontSize,
                                    maxWidth: "200px",
                                }}
                            >
                                {row.status === Status.Ready && (
                                    <b style={{ color: "green" }}>
                                        {displayStatus(row.status)}
                                        {ReadyTimeCalculator(row.readyTime) >=
                                            configTime.noShowTime && (
                                            <>
                                                <Tooltip
                                                    title={
                                                        Status[Status.NoShow]
                                                    }
                                                >
                                                    <Button
                                                        onClick={() =>
                                                            updateStatus(
                                                                row.id,
                                                                Status.NoShow
                                                            )
                                                        }
                                                    >
                                                        <PersonOffIcon
                                                            fontSize="large"
                                                            sx={{
                                                                color: "red",
                                                            }}
                                                        />
                                                    </Button>
                                                </Tooltip>
                                            </>
                                        )}
                                    </b>
                                )}
                                {row.status === Status.Notified && (
                                    <b style={{ color: primaryColor }}>
                                        {displayStatus(row.status)}
                                    </b>
                                )}
                                {row.status === Status.Waiting && (
                                    <b>{displayStatus(row.status)}</b>
                                )}
                                <br />
                                <Typography
                                    variant="caption"
                                    display="block"
                                    gutterBottom
                                >
                                    {row.note}
                                </Typography>
                            </StyledTableCell>
                            <StyledTableCell
                                align="center"
                                sx={{ fontSize: adminTableFontSize }}
                            >
                                <Tooltip title={Status[Status.Notified]}>
                                    <Button
                                        onClick={() => {
                                            if (row.status === Status.Ready) {
                                                alert(
                                                    "it's already set to ready status."
                                                );
                                            } else {
                                                updateStatus(
                                                    row.id,
                                                    Status.Notified
                                                );
                                            }
                                        }}
                                    >
                                        {row.status === Status.Notified ? (
                                            <Badge
                                                badgeContent={
                                                    GetWaitingTime(
                                                        row.notifiedTime
                                                    ) + "+"
                                                }
                                                color="primary"
                                                showZero
                                                max={999}
                                            >
                                                <AlarmOnIcon
                                                    fontSize="large"
                                                    sx={{
                                                        color: customLightBlue,
                                                    }}
                                                />
                                            </Badge>
                                        ) : (
                                            <AlarmOnIcon
                                                fontSize="large"
                                                sx={{ color: customLightBlue }}
                                            />
                                        )}
                                    </Button>
                                </Tooltip>
                                <Tooltip title={Status[Status.Ready]}>
                                    <Button
                                        onClick={() =>
                                            updateStatus(row.id, Status.Ready)
                                        }
                                    >
                                        {row.status === Status.Ready ? (
                                            <Badge
                                                badgeContent={
                                                    GetWaitingTime(
                                                        row.readyTime
                                                    ) + "+"
                                                }
                                                color="success"
                                                showZero
                                                max={999}
                                            >
                                                <CheckCircleIcon
                                                    fontSize="large"
                                                    color="success"
                                                />
                                            </Badge>
                                        ) : (
                                            <CheckCircleIcon
                                                fontSize="large"
                                                color="success"
                                            />
                                        )}
                                    </Button>
                                </Tooltip>
                                <Tooltip title={Status[Status.Complete]}>
                                    <Button
                                        onClick={() => handleClickOpen(row)}
                                    >
                                        <ClearIcon
                                            fontSize="large"
                                            sx={{ color: "black" }}
                                        />
                                    </Button>
                                </Tooltip>
                                <Dialog
                                    open={open.setOpen}
                                    onClose={() =>
                                        setOpen({ row: null, setOpen: false })
                                    }
                                    // TODO: update the deprecated prop
                                    BackdropProps ={{ style: { backgroundColor: 'rgba(52, 52, 52, 0.1)' }, }}
                                >
                                    <DialogTitle>
                                        Are you sure you want to archive this?
                                    </DialogTitle>
                                    <DialogContent sx={{ minHeight: "120px" }}>
                                        {/* insert dialog content here */}
                                        <>
                                            {open.row && (
                                                <ArchiveDialogTable
                                                    name={open.row.userName}
                                                    phone={open.row.phoneNumber}
                                                    size={open.row.size}
                                                />
                                            )}
                                        </>
                                    </DialogContent>
                                    <DialogActions>
                                        <Button
                                            onClick={() =>
                                                setOpen({
                                                    row: null,
                                                    setOpen: false,
                                                })
                                            }
                                            color="primary"
                                        >
                                            Cancle
                                        </Button>
                                        <Button
                                            onClick={() =>
                                                handleAgree(
                                                    open.row!.id,
                                                    Status.Complete
                                                )
                                            }
                                            color="primary"
                                            variant="contained"
                                            autoFocus
                                        >
                                            OK
                                        </Button>
                                    </DialogActions>
                                </Dialog>
                            </StyledTableCell>
                        </StyledTableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
}
