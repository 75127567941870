const DIGITCOUNT = 10;
export function phoneNumberConvertor(phoneNumber: string) {
    if (phoneNumber.length < DIGITCOUNT) return phoneNumber;
    return phoneNumber.replace(/\s/g, "").slice(-DIGITCOUNT);
}

export function phoneNumberConvertorReverse(phoneNumber: string) {
    return `+1 ${phoneNumber.substring(0, 3)} ${phoneNumber.substring(
        3,
        6
    )} ${phoneNumber.substring(6)}}`;
}

export function displayPhoneNumber(phoneNumber: string) {
    const [first, second, third] = [
        phoneNumber.substring(0, 3),
        phoneNumber.substring(3, 6),
        phoneNumber.substring(6),
    ];
    return `(${first}) ${second} - ${third}`;
}
