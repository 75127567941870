import React from "react";
import { Typography, Box } from "@mui/material";

const TermsOfService: React.FC = () => {
    const date = new Date();
    const formattedDate = `${
        date.getMonth() + 1
    } ${date.getDate()}, ${date.getFullYear()}`;
    return (
        <Box mt={2} mb={4}>
            <Typography variant="body2" gutterBottom>
                Last updated: {formattedDate}
            </Typography>
            <Typography variant="body1" paragraph>
                Welcome to QueuingMe! These Terms of Service ("Terms") govern
                your use of the QueuingMe website, mobile application, and
                related services (collectively referred to as the "Service"). By
                accessing or using the Service, you agree to be bound by these
                Terms.
            </Typography>

            <Typography variant="h6" gutterBottom>
                1. Use of Service
            </Typography>
            <Typography variant="body1" paragraph>
                The Service is available to users of all ages. You agree to use
                the Service only for lawful purposes and in compliance with all
                applicable laws and regulations.
            </Typography>
            <Typography variant="body1" paragraph>
                Clients (restaurant owners or operators using the QueuingMe web
                application) can manage waiting lists and send SMS notifications
                to Patrons (customers who patronize the Client’s restaurant).
                Patrons can use the Service by self-checking in through a QR
                code available at the restaurant's entrance (on screen or
                printed). The information entered during self-check-in, such as
                phone numbers and names, is the responsibility of the restaurant
                to manage and protect.
            </Typography>

            <Typography variant="h6" gutterBottom>
                2. Use of Patron Data
            </Typography>
            <Typography variant="body1" paragraph>
                Unless prior written approval is received from QueuingMe, the
                client will not disclose any personal information to
                unaffiliated third parties or use personal information for any
                purpose other than as required to fulfill and process the
                Patron’s reservation or waitlist request or to add the Patron to
                the client’s guest database.
            </Typography>

            <Typography variant="h6" gutterBottom>
                3. User Accounts
            </Typography>
            <Typography variant="body1" paragraph>
                You may be required to create an account to access certain
                features of the Service. You are responsible for maintaining the
                confidentiality of your account credentials and for all
                activities that occur under your account.
            </Typography>

            <Typography variant="h6" gutterBottom>
                4. Intellectual Property
            </Typography>
            <Typography variant="body1" paragraph>
                All content, trademarks, and intellectual property rights
                related to the Service are the property of QueuingMe or its
                licensors. You may not use, reproduce, or distribute any content
                from the Service without prior written permission.
            </Typography>

            <Typography variant="h6" gutterBottom>
                5. User Content
            </Typography>
            <Typography variant="body1" paragraph>
                By submitting content to the Service, you grant QueuingMe a
                worldwide, non-exclusive, royalty-free license to use,
                reproduce, modify, adapt, publish, and distribute the content in
                any form or medium.
            </Typography>

            <Typography variant="h6" gutterBottom>
                6. Disclaimer of Warranties
            </Typography>
            <Typography variant="body1" paragraph>
                The Service is provided on an "as is" and "as available" basis.
                QueuingMe makes no warranties or representations about the
                accuracy or completeness of the Service and disclaims all
                warranties, express or implied.
            </Typography>

            <Typography variant="h6" gutterBottom>
                7. Limitation of Liability
            </Typography>
            <Typography variant="body1" paragraph>
                In no event shall QueuingMe be liable for any indirect,
                incidental, special, or consequential damages arising out of or
                in connection with your use of the Service.
            </Typography>

            <Typography variant="h6" gutterBottom>
                8. Indemnification
            </Typography>
            <Typography variant="body1" paragraph>
                You agree to indemnify and hold harmless QueuingMe and its
                affiliates, officers, directors, employees, and agents from any
                claims, damages, or losses arising out of your use of the
                Service or violation of these Terms.
            </Typography>

            <Typography variant="h6" gutterBottom>
                9. Governing Law
            </Typography>
            <Typography variant="body1" paragraph>
                These Terms shall be governed by and construed in accordance
                with the laws of [Your Jurisdiction], without regard to its
                conflict of law provisions.
            </Typography>

            <Typography variant="h6" gutterBottom>
                10. Changes to Terms
            </Typography>
            <Typography variant="body1" paragraph>
                QueuingMe reserves the right to modify or update these Terms at
                any time. Your continued use of the Service after any changes
                indicates your acceptance of the revised Terms.
            </Typography>

            <Typography variant="h6" gutterBottom>
                11. Monthly Fees
            </Typography>
            <Typography variant="body1" paragraph>
                Monthly fees must be paid in advance. If payment is not received
                before the start of the month, your account may be deactivated
                without notice. A penalty of $30 may be applied to reactivate a
                deactivated account.
            </Typography>

            <Typography variant="h6" gutterBottom>
                12. Trial Plan
            </Typography>
            <Typography variant="body1" paragraph>
                QueuingMe may offer a trial plan to certain eligible clients for
                a limited number of days. At the end of the trial period, access
                to the QueuingMe service will expire, and clients will lose
                access to the service.
            </Typography>

            <Typography variant="h6" gutterBottom>
                13. Basic Plan Charges
            </Typography>
            <Typography variant="body1" paragraph>
                If you are using the Basic Plan and your waiting list exceeds
                1000 entries per month, an additional charge of $20 will be
                applied for every 500 additional entries. If additional charges
                are incurred, we can assist you in reviewing your waiting list
                entries upon request.
            </Typography>

            <Typography variant="h6" gutterBottom>
                14. Annual Fee Increase
            </Typography>
            <Typography variant="body1" paragraph>
                QueuingMe reserves the right to increase monthly fees once per
                calendar year. You will be notified of any changes to your
                monthly fees at least 30 days in advance.
            </Typography>

            <Typography variant="h6" gutterBottom>
                15. Contact Us
            </Typography>
            <Typography variant="body1" paragraph>
                If you have any questions or concerns about these Terms, please
                contact us at{" "}
                <a href="mailto:queuingme.business@gmail.com">
                    queuingme.business@gmail.com
                </a>
                .
            </Typography>
        </Box>
    );
};

export default TermsOfService;
