import { Guid } from "guid-typescript";
import { Restaurant } from "./restaurant";

export interface Queue {
    id: Guid;
    size: number;
    sizeKids: number;
    userName: string;
    phoneNumber: string;
    isBbq: boolean;
    note: string;
    status: Status;
    createdTime: string;
    notifiedTime: string;
    readyTime: string;
    archivedTime: string;
    position?: number;
    restaurant: Restaurant;
}

export enum Status {
    Waiting,
    Notified,
    Ready,
    Complete,
    Cancel,
    NoShow,
}

export default function displayStatus(status: Status) {
    switch (status) {
        case Status.Waiting:
            return "Waiting";
        case Status.Notified:
            return "Notified";
        case Status.Ready:
            return "Ready";
        case Status.Complete:
            return "Complete";
        case Status.Cancel:
            return "Cancel";
        case Status.NoShow:
            return "NoShow";
        default:
            return "Waiting";
    }
}
