import { useNavigate, useParams } from "react-router-dom";
import agent from "../../app/api/agent";
import { Queue, Status } from "../../app/models/queue";
import { useState, useEffect } from "react";
import {
    Avatar,
    Box,
    Button,
    Card,
    CardContent,
    CardHeader,
    Chip,
    Divider,
    Grid,
    IconButton,
    Menu,
    MenuItem,
    Tooltip,
    Typography,
} from "@mui/material";
import LoadingComponent from "../../app/layout/LoadingComponent";
import RefreshSharpIcon from "@mui/icons-material/RefreshSharp";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import PlaceIcon from "@mui/icons-material/Place";
import PhoneIcon from "@mui/icons-material/Phone";
import InfoIcon from "@mui/icons-material/Info";
import { alertCancelMessage } from "../../app/configuration/alert";
import { displayGuestDetailTitle } from "../../app/extentions/DisplayMessage";
import Link from "@mui/material/Link";
import { successColor } from "../../app/style/customColor";
import { BBQrestaurants, restaurant } from "../../app/configuration/restaurant";
import NotFound from "../errors/NotFound";
import { displayPhoneNumber } from "../../app/configuration/phoneNumberConvertor";
import { guestMsg } from "../../app/configuration/guestMsg";
import { getExpectedWaitingTime } from "../../app/extentions/WaitingTime";
import RestaurantNameDisplay from "../../app/configuration/restaurantNameDisplay";

const ITEM_HEIGHT = 48;

const options = ["QueuingMe"];

export default function GuestQueueDetailsPage() {
    const { id, restaurantParam } = useParams<{
        id: string;
        restaurantParam: string;
    }>();
    const [queue, setQueue] = useState<Queue>();
    const [loading, setLoading] = useState(true);
    const [refresh, setRefresh] = useState(true);
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const navigate = useNavigate();
    const open = Boolean(anchorEl);

    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    function refreshPage() {
        setRefresh(!refresh);
    }

    async function cancelQueue() {
        let currentStatus = Status.Waiting;
        await agent.Display.details(id!, restaurantParam!).then((res) => {
            currentStatus = res!.status;
            if (currentStatus === Status.NoShow) {
                alert("It's marked as NoShow. Please line up again.");
                navigate(`/${restaurantParam}/public/guest`);
                return;
            }
            if (currentStatus === Status.Complete) {
                alert("It's completed queue. Please line up again.");
                navigate(`/${restaurantParam}/public/guest`);
                return;
            }
            agent.Display.updateStatusByGuest({
                id: id,
                status: Status.Cancel,
                restaurantName: restaurantParam,
            }).then((res) => {
                if (res.id === id) {
                    let msg = alertCancelMessage(currentStatus);
                    alert(msg);
                    navigate(`/${restaurantParam}/public/guest`);
                }
            });
        });
    }

    useEffect(() => {
        agent.Display.details(id!, restaurantParam!)
            .then((res) => {
                res.id = id;
                setQueue(res);
                setLoading(false);
            })
            .catch((e) => {
                console.error(e.response);
                alert("Problem occured!");
                navigate(`/${restaurantParam}/public/guest`);
            });
    }, [refresh]); //eslint-disable-line

    if (loading) return <LoadingComponent message="Loading..." />;

    if (!queue) return <NotFound />;

    if (!restaurant.includes(restaurantParam!.toLowerCase()))
        return <NotFound />;

    return (
        <>
            <Card
                style={{
                    maxWidth: 450,
                    margin: "10px auto 0",
                    padding: "20px 5px",
                }}
                sx={{ boxShadow: 10 }}
            >
                <CardHeader
                    avatar={
                        <Avatar
                            sx={{ bgcolor: successColor, fontWeight: "bold" }}
                        >
                            {queue!.userName[0]}
                        </Avatar>
                    }
                    action={
                        <IconButton
                            aria-label="more"
                            id="long-button"
                            aria-controls={open ? "long-menu" : undefined}
                            aria-expanded={open ? "true" : undefined}
                            aria-haspopup="true"
                            onClick={handleClick}
                        >
                            <MoreVertIcon />
                        </IconButton>
                    }
                    title={displayGuestDetailTitle(queue!)}
                    subheader={
                        "Checked in " +
                        new Date(queue!.createdTime).toLocaleString()
                    }
                />
                <Menu
                    id="long-menu"
                    MenuListProps={{
                        "aria-labelledby": "long-button",
                    }}
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}
                    PaperProps={{
                        style: {
                            maxHeight: ITEM_HEIGHT * 4.5,
                            width: "20ch",
                        },
                    }}
                >
                    {options.map((option) => (
                        <MenuItem key={option} onClick={handleClose}>
                            {option}
                        </MenuItem>
                    ))}
                </Menu>
                <Grid display="flex" mb="20px">
                    <Grid
                        display="flex"
                        xs={2}
                        container
                        item
                        alignItems="center"
                        justifyContent="center"
                    >
                        <img
                            src={`/images/${restaurantParam}Icon.png`}
                            width="80%"
                            style={{ margin: "auto" }}
                            alt="logo"
                        />
                    </Grid>
                    <Grid xs={10} item alignSelf="center" ml="20px">
                        <Typography variant="h6">
                            {RestaurantNameDisplay(
                                queue.restaurant.restaurantName
                            )}
                        </Typography>
                        <Link
                            color="inherit"
                            href={"tel:" + queue.restaurant.phoneNumber}
                        >
                            <Typography
                                variant="subtitle2"
                                sx={{ display: "flex", alignItems: "center" }}
                            >
                                <>
                                    <PhoneIcon fontSize="small" />
                                    <span>
                                        {displayPhoneNumber(
                                            queue.restaurant.phoneNumber
                                        )}
                                    </span>
                                </>
                            </Typography>
                        </Link>
                        <Link
                            color="inherit"
                            href={
                                "https://www.google.com/maps/search/?api=1&query=" +
                                queue.restaurant.restaurantName
                            }
                            target="blank"
                        >
                            <Typography
                                variant="subtitle2"
                                sx={{ display: "flex", alignItems: "center" }}
                            >
                                <PlaceIcon fontSize="small" />
                                {`${queue.restaurant.address?.street}, 
                                ${queue.restaurant.address?.city}, 
                                ${queue.restaurant.address?.province} 
                                ${queue.restaurant.address?.postalCode}`}
                            </Typography>
                        </Link>
                    </Grid>
                </Grid>

                <Divider>
                    <Chip sx={{ fontWeight: "bold" }} label="Details" />
                </Divider>

                <CardContent>
                    <Grid container spacing={1}>
                        <Grid xs={4} item>
                            <Typography color="grey">Name</Typography>
                        </Grid>
                        <Grid xs={8} item>
                            <Typography fontWeight="bold">
                                {queue!.userName}
                            </Typography>
                        </Grid>
                        <Grid xs={4} item>
                            <Typography color="grey">Phone Number</Typography>
                        </Grid>
                        <Grid xs={8} item>
                            <Typography fontWeight="bold">
                                {displayPhoneNumber(queue!.phoneNumber)}
                            </Typography>
                        </Grid>
                        <Grid xs={4} item>
                            <Typography color="grey">Size</Typography>
                        </Grid>
                        <Grid xs={8} item>
                            <Typography fontWeight="bold">
                                {queue!.size}
                            </Typography>
                        </Grid>
                        <Grid xs={4} item>
                            <Typography color="grey">Kids Size</Typography>
                        </Grid>
                        <Grid xs={8} item>
                            <Typography fontWeight="bold">
                                {queue!.sizeKids}
                            </Typography>
                        </Grid>
                        <Grid xs={4} item>
                            <Typography color="grey">Position</Typography>
                        </Grid>
                        <Grid xs={8} item>
                            <Typography fontWeight="bold">
                                {queue!.position! >= 1 &&
                                    [Status.Waiting, Status.Notified].includes(
                                        queue!.status
                                    ) &&
                                    queue!.position}
                                {queue!.status === Status.Ready &&
                                    "Your table is ready"}
                                {![
                                    Status.Waiting,
                                    Status.Notified,
                                    Status.Ready,
                                ].includes(queue!.status) && "It's expired."}
                            </Typography>
                        </Grid>
                        {[Status.Waiting, Status.Notified].includes(
                            queue!.status
                        ) && (
                            <>
                                <Grid xs={4} item>
                                    <Typography color="grey">
                                        Expected wait
                                    </Typography>
                                </Grid>
                                <Grid xs={8} item>
                                    <Box display="flex" alignContent="center">
                                        <Typography fontWeight="bold">
                                            {getExpectedWaitingTime(
                                                queue!.position!
                                            )}
                                        </Typography>
                                        <Tooltip
                                            title={
                                                <>
                                                    {
                                                        guestMsg
                                                            .GuestDetailMessage
                                                            .expectedWaitTime
                                                            .English
                                                    }
                                                </>
                                            }
                                        >
                                            <InfoIcon />
                                        </Tooltip>
                                    </Box>
                                </Grid>
                            </>
                        )}
                        <Grid xs={4} item>
                            <Typography color="grey">Status</Typography>
                        </Grid>
                        <Grid xs={8} item alignContent="center">
                            <Typography fontWeight="bold">
                                {Status[queue!.status]}
                            </Typography>
                        </Grid>
                        {BBQrestaurants.includes(
                            restaurantParam!.toLowerCase()
                        ) && (
                            <>
                                <Grid xs={4} item>
                                    <Typography color="grey">BBQ</Typography>
                                </Grid>
                                <Grid xs={8} item alignContent="center">
                                    <Typography fontWeight="bold">
                                        {queue!.isBbq ? "Yes" : "No"}
                                    </Typography>
                                </Grid>
                            </>
                        )}

                        <Grid xs={4} item>
                            <Typography color="grey">Note</Typography>
                        </Grid>
                        <Grid xs={8} item>
                            <Typography fontWeight="bold">
                                {queue!.note}
                            </Typography>
                        </Grid>
                        <Grid xs={12} sm={6} sx={{ mt: "20px" }} item>
                            <Button
                                fullWidth
                                variant="contained"
                                onClick={refreshPage}
                            >
                                Refresh Status
                                <RefreshSharpIcon />
                            </Button>
                        </Grid>
                        <Grid xs={12} sm={6} sx={{ mt: "20px" }} item>
                            <Button
                                fullWidth
                                variant="contained"
                                color="error"
                                onClick={cancelQueue}
                            >
                                Cancel Queue
                            </Button>
                        </Grid>
                        <Grid xs={12} sx={{ mt: "20px" }} item>
                            <Button
                                fullWidth
                                variant="contained"
                                onClick={() =>
                                    navigate(`/${restaurantParam}/public/guest`)
                                }
                            >
                                Join Again
                            </Button>
                        </Grid>
                        <Grid xs={12} sx={{ mt: "20px", mb: "20px" }} item>
                            <Typography variant="subtitle2">
                                {
                                    guestMsg.GuestDetailMessage.content
                                        .footerEnglish
                                }{" "}
                                <br />
                                {
                                    guestMsg.GuestDetailMessage.content
                                        .footerKorean
                                }
                            </Typography>
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>
        </>
    );
}
