import { Container, Paper, Typography, Divider, Button } from "@mui/material";
import { useNavigate } from "react-router-dom";

export default function NotFound() {
    const navigate = useNavigate();
    return (
        <Container component={Paper} style={{ height: 400 }}>
            <Typography gutterBottom variant={"h3"}>
                Not Found
            </Typography>
            <Divider />
            <Button onClick={() => navigate(-1)} fullWidth>
                Go back to the app
            </Button>
        </Container>
    );
}
