import { Container } from "@mui/material";
import TechnologyPage from "./HomePage/TechnologyPage";

export default function HomePage() {
    // return <h1>Welcome to the app!</h1>;
    return (
        <Container maxWidth={false} sx={{ marginTop: { md: "5rem" }, padding: { xs: 0 } }}>
            <TechnologyPage />
        </Container>
    );
}
