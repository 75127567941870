import React from "react";
import Link from "@mui/material/Link";
import { Queue } from "../../app/models/queue";
import { Link as RouterLink } from "react-router-dom";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from "@mui/material";

interface Props {
    open: boolean;
    onClose: () => void;
    queue: Queue | null;
    restaurantName: string;
}

const DisplayActiveQueuesModal: React.FC<Props> = ({
    open,
    onClose,
    queue,
    restaurantName,
}) => {
    return (
        <Dialog open={open} onClose={onClose}>
            <DialogTitle>Check Guest View</DialogTitle>
            <DialogContent>
                <Typography variant="body1">
                    {queue!.userName} {`(${queue!.size + queue!.sizeKids}) `}is
                    currently in the queue.
                    
                </Typography>
                <Typography variant="body2"></Typography>
            </DialogContent>
            <DialogActions>
                <Button
                    onClick={() =>
                        onClose()
                    }
                    color="primary"
                >
                    Cancel
                </Button>
                <Button
                    color="primary"
                    variant="contained"
                    autoFocus
                >
                    <Link
                        component={RouterLink}
                        to={`/${restaurantName}/public/guest/${queue!.id}`}
                        color={"inherit"}
                        underline="none"
                    >
                        View Details
                    </Link>
                    
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default DisplayActiveQueuesModal;
