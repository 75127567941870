import { Typography } from "@mui/material";

interface Props {
    restaurantName: string;
}

export default function QRcode({ restaurantName }: Props) {
    return (
        <div
            style={{
                position: "fixed",
                width: "150px",
                zIndex: 999,
                bottom: "35px",
            }}
        >
            <Typography
                variant="h6"
                pt={1}
                sx={{
                    backgroundColor: "white",
                    color: "black",
                    textAlign: "center",
                    fontWeight: "bold",
                }}
            >
                Scan QR
                <br />
                to join the line
            </Typography>
            <img
                src={"/images/" + restaurantName + "QR.png"}
                width="100%"
                alt="QRcode"
            />
        </div>
    );
}
