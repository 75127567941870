import { useRef } from "react";
import { Container, Paper } from "@mui/material";
import HomeHeader from "./HomeHeader";
import CompanyPage from "./CompanyPage";
import SolutionPage from "./SolutionPage";
import TechnologyPage from "./TechnologyPage";
import ContactPage from "./ContactPage";
import { homeColor } from "../../style/customColor";
import HomeFooter from "./HomeFooter";
import ReviewPage from "./ReviewPage";

interface HomeProps {}

const pages: string[] = ["Company", "Solution", "Technologi", "Demo"];

export default function Home({}: HomeProps) {
    const companyRef = useRef<HTMLDivElement | null>(null);
    const solutionRef = useRef<HTMLDivElement | null>(null);
    const technologyRef = useRef<HTMLDivElement | null>(null);
    const demoRef = useRef<HTMLDivElement | null>(null);

    const handleButtonClick = (page: string) => {
        switch (page) {
            case "Company":
                companyRef.current?.scrollIntoView({ behavior: "smooth" });
                break;
            case "Solution":
                solutionRef.current?.scrollIntoView({ behavior: "smooth" });
                break;
            case "Technology":
                technologyRef.current?.scrollIntoView({ behavior: "smooth" });
                break;
            case "Demo":
                demoRef.current?.scrollIntoView({ behavior: "smooth" });
                break;
            default:
                break;
        }
    };

    return (
        <div style={{ backgroundColor: homeColor.background_cobaltBlue }}>
            <HomeHeader handleButtonClick={handleButtonClick} />
            <div style={{ minHeight: "100vh", padding: 0 }}>
                <Container
                    maxWidth="xl"
                    sx={{
                        height: "100%",
                        backgroundColor: homeColor.background_cobaltBlue,
                        padding: {
                            xs: 0,
                        },
                    }}
                >
                    <Paper
                        elevation={3}
                        sx={{
                            margin: "0rem 0rem",
                        }}
                        ref={companyRef}
                    >
                        <CompanyPage handleButtonClick={handleButtonClick} />
                    </Paper>
                </Container>
            </div>
            <div>
                <Container maxWidth="xl" sx={{ padding: { xs: 0 } }}>
                    <Paper
                        elevation={3}
                        sx={{
                            padding: "4rem 0",
                            backgroundColor: "whitesmoke",
                            borderRadius: 0,
                        }}
                        ref={solutionRef}
                    >
                        <SolutionPage />
                    </Paper>
                </Container>
                <Container
                    maxWidth="xl"
                    sx={{
                        padding: { xs: 0 },
                    }}
                >
                    <Paper
                        elevation={3}
                        sx={{
                            padding: "6rem 0",
                            backgroundColor: homeColor.background_cobaltBlue,
                            boxShadow: 0,
                        }}
                        ref={technologyRef}
                    >
                        <TechnologyPage />
                    </Paper>
                </Container>
                <Container maxWidth="lg" sx={{ padding: 0 }}>
                    <Paper
                        elevation={3}
                        sx={{
                            padding: "6rem 1.5rem",
                            backgroundColor: "whitesmoke",
                            marginTop: "2rem",
                            maxWidth: "700px",
                            display: "block",
                            margin: "auto",
                            borderRadius: {xs: "0rem", md: "2rem"},
                        }}
                        ref={demoRef}
                    >
                        <ContactPage />
                    </Paper>
                </Container>
                <Container
                    maxWidth={false}
                    sx={{
                        padding: 0,
                        justifyContent: "center",
                        alignItems: "center",
                        minHeight: {xs: "10vh", md: "20vh"},
                        background: `url('/images/QueuingMePatternWhite.png')`,
                        backgroundSize: {xs: "30vh", md: "50vh"},
                    }}
                >
                    <ReviewPage />
                </Container>
                <Container
                    maxWidth={false}
                    sx={{
                        // padding: "0.5rem 0.5rem 3rem 0.5rem",
                        backgroundColor: "whitesmoke",
                        marginBottom: "-5vh"
                    }}
                >
                    <HomeFooter />
                </Container>
            </div>
        </div>
    );
}
