import React from "react";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { Typography, Box } from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.common.black,
        color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "&:nth-of-type(odd)": {
        backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    "&:last-child td, &:last-child th": {
        border: 0,
    },
}));

function createData(feature: string, basicplan: string, premiumplan: string) {
    return { feature, basicplan, premiumplan };
}

const rows = [
    // createData("Feature", "Basic", "Premium"),
    createData("Monthly cost", "$69", "$149"),
    createData("Book covered*", "1,000", "Unlimited"),
    createData("Device-free*", "yes", "yes"),
    createData("SMS", "yes", "yes"),
    createData("QR code", "yes", "yes"),
    createData("Self check-in", "yes", "yes"),
    createData("Multi restaurant", "yes", "yes"),
    createData("Full support", "yes", "yes"),
    createData("Weekly report", "yes", "yes"),
    createData("Tablet w/ subscription*", "yes", "yes"),
    createData("Custom report", "no", "yes"),
    createData("Kiosk*", "no", "yes"),
];

const Pricing: React.FC = () => {
    const date = new Date();
    const formattedDate = `${
        date.getMonth() + 1
    } ${date.getDate()}, ${date.getFullYear()}`;
    return (
        <Box mt={2} mb={4}>
            <Typography variant="body2" paragraph>
                Last updated: {formattedDate}
            </Typography>
            <TableContainer component={Paper} sx={{ mb: "1rem" }}>
                <Table aria-label="customized table">
                    <TableHead>
                        <TableRow>
                            <StyledTableCell>Feature</StyledTableCell>
                            <StyledTableCell align="center">
                                Basic
                            </StyledTableCell>
                            <StyledTableCell align="center">
                                Premium
                            </StyledTableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {rows.map((row, idx) => (
                            <StyledTableRow key={idx}>
                                <StyledTableCell component="th" scope="row">
                                    {row.feature}
                                </StyledTableCell>
                                <StyledTableCell align="center">
                                    {row.basicplan === "yes" ? (
                                        <CheckCircleIcon color="success" />
                                    ) : row.basicplan === "no" ? (
                                        <CancelIcon />
                                    ) : (
                                        row.basicplan
                                    )}
                                </StyledTableCell>
                                <StyledTableCell align="center">
                                    {row.premiumplan === "yes" ? (
                                        <CheckCircleIcon color="success" />
                                    ) : row.premiumplan === "no" ? (
                                        <CancelIcon />
                                    ) : (
                                        row.premiumplan
                                    )}
                                </StyledTableCell>
                            </StyledTableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <Typography
                variant="caption"
                paragraph
                sx={{ lineHeight: "1rem", mb: "0.5rem" }}
            >
                * 1) QueueingMe is optimized in tablet view. Restaurants can
                also use their mobile devices, or laptops.
            </Typography>
            <Typography
                variant="caption"
                paragraph
                sx={{ lineHeight: "1rem", mb: "0.5rem" }}
            >
                * 2) An additional charge of $20 will be applied for every 500
                additional entries.
            </Typography>
            <Typography
                variant="caption"
                paragraph
                sx={{ lineHeight: "1rem", mb: "0.5rem" }}
            >
                * 3) Kiosk, Tablet w/ subscription: Tablet is provided with a
                subscription. A 6-month advance payment is required. This offer
                is only available during the promotional period.
            </Typography>
        </Box>
    );
};

export default Pricing;
