import Avatar from "@mui/material/Avatar";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { Paper } from "@mui/material";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { FieldValues, useForm } from "react-hook-form";
import { useAppDispatch } from "../../app/store/configureStore";
import { signInUser } from "./accountSlice";
import { LoadingButton } from "@mui/lab";

export default function Login() {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const location = useLocation();
    const {
        register,
        handleSubmit,
        formState: { errors, isValid, isSubmitting },
    } = useForm({
        mode: "onTouched",
    });
    async function submitForm(data: FieldValues) {
        try {
            await dispatch(signInUser(data)).then((res) => {
                if (res.type === "account/signInUser/rejected") return;
                navigate(location.state?.from || "/restaurantCards");
            });
        } catch (error) {
            console.log(error);
        }
    }

    return (
        <Container
            component={Paper}
            maxWidth="sm"
            sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                mt: "4rem",
                p: 4,
                boxShadow: 10,
                width: "95%"
            }}
        >
            <img
                src={`/images/QueuingMeLogo.png`}
                width="130px"
                style={{ margin: "auto" }}
                alt="logo"
            />
            <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
                <LockOutlinedIcon />
            </Avatar>
            <Typography component="h1" variant="h5">
                Sign in
            </Typography>
            <Box
                component="form"
                onSubmit={handleSubmit(submitForm)}
                noValidate
                sx={{ mt: 1 }}
            >
                <TextField
                    margin="normal"
                    required
                    fullWidth
                    label="User Name"
                    autoComplete="username"
                    autoFocus
                    {...register("username", {
                        required: "User name is required",
                    })}
                    error={!!errors.username}
                    helperText={errors?.username?.message as string}
                />
                <TextField
                    margin="normal"
                    required
                    fullWidth
                    label="Password"
                    type="password"
                    {...register("password", {
                        required: "Password is required",
                    })}
                    error={!!errors.password}
                    helperText={errors?.password?.message as string}
                />
                <LoadingButton
                    disabled={!isValid}
                    loading={isSubmitting}
                    type="submit"
                    fullWidth
                    variant="contained"
                    sx={{ mt: 3, mb: 2 }}
                >
                    Sign In
                </LoadingButton>
                <Grid container>
                    <Grid item>
                        <Link to={`/register`}>
                            <Typography variant="body1">
                                Don't have an account? Sign Up
                            </Typography>
                        </Link>
                    </Grid>
                </Grid>
            </Box>
        </Container>
    );
}
