import { Queue, Status } from "../models/queue";

export function alertCancelMessage(status: Status): string {
    let msg = "";
    switch (status) {
        case Status.Waiting:
            msg = "Thanks for letting us know in advance.";
            break;
        case Status.Notified:
            msg = "Thanks for letting us know in advance.";
            break;
        case Status.Ready:
            msg =
                "Your table is ready by our server.\nPlease let us know in advance for the next time.";
            break;
        case Status.Cancel:
            msg = "Succeessfully canceled.\nThanks for letting us know!";
            break;
        case Status.Complete:
            msg = "It's already completed.";
            break;
        case Status.NoShow:
            msg =
                "Your seat was held for 5 minutes and already processed as NoShow.";
            break;
        default:
            break;
    }
    return msg;
}