import React from "react";
import { Typography, Box } from "@mui/material";

const PrivacyPolicy: React.FC = () => {
    const date = new Date();
    const formattedDate = `${
        date.getMonth() + 1
    } ${date.getDate()}, ${date.getFullYear()}`;
    return (
        <Box mt={2} mb={4}>
            <Typography variant="body2" paragraph>
                Last updated: {formattedDate}
            </Typography>
            <Typography variant="body1" paragraph>
                At QueuingMe, we are committed to protecting your privacy. This
                Privacy Policy explains how we collect, use, and disclose
                information about you when you use our Service.
            </Typography>

            <Typography variant="h6" gutterBottom>
                1. Information We Collect
            </Typography>
            <Typography variant="body1" component="div">
                <ul>
                    <li>
                        <strong>Personal Information:</strong> We may collect
                        personal information such as your name, email address,
                        phone number, and location when you create an account or
                        use our Service.
                    </li>
                    <li>
                        <strong>Usage Information:</strong> We may collect
                        information about your use of the Service, such as the
                        pages you visit, the features you use, and the actions
                        you take.
                    </li>
                </ul>
            </Typography>

            <Typography variant="h6" gutterBottom>
                2. How We Use Your Information
            </Typography>
            <Typography variant="body1" paragraph>
                We use the information we collect to provide, maintain, and
                improve the Service, to communicate with you, and to personalize
                your experience. We may also use your information to send you
                promotional and marketing communications, with your consent.
            </Typography>

            <Typography variant="h6" gutterBottom>
                3. Information Sharing
            </Typography>
            <Typography variant="body1" paragraph>
                We may share your information with third-party service providers
                who assist us in operating the Service, conducting business, or
                servicing you. We may also share your information in response to
                legal requests or to protect our rights, property, or safety.
            </Typography>

            <Typography variant="h6" gutterBottom>
                4. Data Retention
            </Typography>
            <Typography variant="body1" paragraph>
                We retain your personal information for as long as necessary to
                fulfill the purposes outlined in this Privacy Policy unless a
                longer retention period is required or permitted by law.
            </Typography>

            <Typography variant="h6" gutterBottom>
                5. Your Choices
            </Typography>
            <Typography variant="body1" component="div">
                <ul>
                    <li>
                        You may update your account information at any time by
                        logging into your account settings.
                    </li>
                    <li>
                        You may opt-out of receiving promotional communications
                        from us by following the instructions in the
                        communication or by contacting us directly.
                    </li>
                </ul>
            </Typography>

            <Typography variant="h6" gutterBottom>
                6. Security
            </Typography>
            <Typography variant="body1" paragraph>
                We take reasonable measures to protect your personal information
                from unauthorized access, use, or disclosure. However, no method
                of transmission over the Internet or electronic storage is 100%
                secure.
            </Typography>
            <Typography variant="body1" paragraph>
                We use Microsoft Azure for our cloud services, which is known
                for its robust security measures and compliance with various
                international and industry-specific compliance standards.
            </Typography>

            <Typography variant="h6" gutterBottom>
                7. Children's Privacy
            </Typography>
            <Typography variant="body1" paragraph>
                Our Service is designed to be safe and appropriate for users of
                all ages, including children. We do not knowingly collect
                personal information from children without parental consent. If
                you become aware that a child has provided us with personal
                information without parental consent, please contact us.
            </Typography>

            <Typography variant="h6" gutterBottom>
                8. Changes to Privacy Policy
            </Typography>
            <Typography variant="body1" paragraph>
                We may update this Privacy Policy from time to time. If we make
                any material changes, we will notify you by email or by posting
                the updated Privacy Policy on the Service.
            </Typography>

            <Typography variant="h6" gutterBottom>
                9. Compliance with British Columbia's Personal Information
                Protection Act (PIPA)
            </Typography>
            <Typography variant="body1" paragraph>
                In accordance with the British Columbia Personal Information
                Protection Act (PIPA), we are committed to safeguarding the
                privacy of our patrons. Any personal information collected
                through the Service, such as phone numbers and names, will be
                handled responsibly and in compliance with PIPA. Clients are
                responsible for the protection of personal data collected from
                patrons and must take appropriate measures to secure it.
            </Typography>
            <Typography variant="body1" paragraph>
                Patrons have the right to access their personal information held
                by the restaurant and to request corrections of any
                inaccuracies. For any concerns regarding personal data, patrons
                can contact the restaurant directly.
            </Typography>

            <Typography variant="h6" gutterBottom>
                10. Contact Us
            </Typography>
            <Typography variant="body1" paragraph>
                If you have any questions or concerns about this Privacy Policy,
                please contact us at{" "}
                <a href="mailto:queuingme.business@gmail.com">
                    queuingme.business@gmail.com
                </a>
                .
            </Typography>
        </Box>
    );
};

export default PrivacyPolicy;
