import { Container, Grid, Typography, Divider, Link } from "@mui/material";
import { primaryColor } from "../../../app/style/customColor";
import SupportAgentIcon from "@mui/icons-material/SupportAgent";
import EmailIcon from "@mui/icons-material/Email";
import PhoneIcon from "@mui/icons-material/Phone";
import { displayPhoneNumber } from "../../../app/configuration/phoneNumberConvertor";
import LinkedInIcon from "@mui/icons-material/LinkedIn";

export default function Support() {
    return (
        <Container>
            <Grid container marginTop={3}>
                <SupportAgentIcon fontSize="large" color="primary" />
                <Typography
                    variant="h5"
                    fontWeight="bold"
                    color={primaryColor}
                    sx={{ marginLeft: 1 }}
                >
                    Support
                </Typography>
            </Grid>
            <Divider />
            {/* <Grid container display="flex" marginTop={3}>
                <EmailIcon color="primary" />
                <Typography variant="body1" sx={{ marginLeft: 1 }}>
                    <a href="mailto:Queuingme.business@gmail.com">
                        Queuingme.business@gmail.com
                    </a>
                </Typography>
            </Grid> */}

            <Grid container display="flex" marginTop={3}>
                <Link
                    color="inherit"
                    href={"mailto:queuingme.business@gmail.com"}
                >
                    <Typography
                        variant="subtitle1"
                        sx={{ display: "flex", alignItems: "center" }}
                    >
                        <EmailIcon />
                        <span>&nbsp;queuingme.business@gmail.com</span>
                    </Typography>
                </Link>
            </Grid>

            <Grid container display="flex" marginTop={3}>
                <Link color="inherit" href={"tel:" + 6046794111}>
                    <Typography
                        variant="subtitle1"
                        sx={{ display: "flex", alignItems: "center" }}
                    >
                        <PhoneIcon />
                        <span>&nbsp;{displayPhoneNumber("6046794111")}</span>
                    </Typography>
                </Link>
            </Grid>
            <Typography variant="body2" marginTop={3}>
                <Link
                    color="inherit"
                    href="https://ca.linkedin.com/company/queuingme"
                    target="_blank"
                >
                    <LinkedInIcon color="primary" fontSize="large" />
                    Follow us!
                </Link>
            </Typography>
            <Typography variant="body2" marginTop={3}>
                Thanks for using QueuingMe! We provide 24/7 support for our
                customers.
                <br />
                If you have any questions or need help, please feel free to
                contact us.
                <br />
                Please leave a text message or email if we are not able to
                answer your call.
            </Typography>
        </Container>
    );
}
