import { LoadingButton } from "@mui/lab";
import {
    Box,
    Card,
    CardContent,
    Grid,
    TextField,
    Typography,
} from "@mui/material";
import SendIcon from "@mui/icons-material/Send";
import agent from "../../api/agent";
import { useForm } from "react-hook-form";
import { useState } from "react";

export default function ContactPage() {
    const [btnLoading, setBtnLoading] = useState(false);
    const {
        register,
        handleSubmit,
        formState: { errors, isValid },
    } = useForm({
        mode: "all",
    });
    return (
        <Grid container padding={0}>
            <Grid item xs={12}>
                <Card
                    style={{
                        maxWidth: 450,
                        margin: "10px auto",
                        padding: "20px 5px",
                    }}
                    sx={{ boxShadow: 10 }}
                >
                    <img
                        src={`/images/QueuingMeIcon.png`}
                        style={{
                            display: "block",
                            margin: "auto",
                            width: "20%",
                        }}
                        alt="logo"
                    />
                    <CardContent>
                        <Typography variant="body2" align="center">
                            Please fill in the input fields below
                        </Typography>
                        <Box
                            component="form"
                            onSubmit={handleSubmit((data) => {
                                setBtnLoading(true);
                                console.log(data.message);
                                agent.Message.sendInvitationMessage(data)
                                    .then((response) => {
                                        console.log(response);
                                        alert(
                                            "Email is sent. Please check spam folder if you do not see the email in your inbox. Thank you for your request. We will reach out to you soon!"
                                        );
                                    })
                                    .catch((error) => {
                                        console.error(error.response);
                                        alert(
                                            "Error sending email. Please reach out to queuingme.business@gmail.com!"
                                        );
                                    })
                                    .finally(() => {
                                        setBtnLoading(false);
                                    });
                            })}
                            noValidate
                            sx={{ mt: 1 }}
                        >
                            <Grid container spacing={1}>
                                <Grid xs={12} item>
                                    <TextField
                                        required
                                        fullWidth
                                        id="outlined-required"
                                        label="Name"
                                        placeholder="Jane"
                                        margin="normal"
                                        {...register("userName", {
                                            required: "Name is required",
                                            pattern: {
                                                value: /^([^0-9]*)$/,
                                                message:
                                                    "Please not include any numbers",
                                            },
                                        })}
                                        error={!!errors.userName}
                                        helperText={errors?.userName?.message?.toString()}
                                    />
                                </Grid>

                                <Grid xs={12} item>
                                    <TextField
                                        margin="normal"
                                        required
                                        fullWidth
                                        label="Email Address"
                                        autoComplete="email"
                                        {...register("emailAddress", {
                                            required: "Email is required",
                                            pattern: {
                                                value: /^\w+[\w-.]*@\w+((-\w+)|(\w*))\.[a-z]{2,3}$/,
                                                message:
                                                    "Not a valid email address",
                                            },
                                        })}
                                        error={!!errors.emailAddress}
                                        helperText={errors?.emailAddress?.message?.toString()}
                                    />
                                </Grid>

                                <Grid xs={12} item>
                                    <TextField
                                        required
                                        fullWidth
                                        id="outlined-required-d"
                                        label="Restaurant Name"
                                        placeholder="Restaurant Name"
                                        margin="normal"
                                        {...register("restaurantName", {
                                            required:
                                                "Please provide full restaurant name",
                                            pattern: {
                                                value: /.{3,}/,
                                                message:
                                                    "Restaurant Name is required",
                                            },
                                        })}
                                        error={!!errors.restaurantName}
                                        helperText={errors?.restaurantName?.message?.toString()}
                                    />
                                </Grid>
                                <Grid xs={12} item>
                                    <LoadingButton
                                        fullWidth
                                        loading={btnLoading}
                                        disabled={!isValid}
                                        variant="contained"
                                        type="submit"
                                        sx={{
                                            mt: 3,
                                            backgroundColor: "secondary.main",
                                            fontWeight: 700,
                                            fontSize: "1.2rem",
                                        }}
                                    >
                                        REQUEST A FREE DEMO&nbsp;
                                        <SendIcon />
                                    </LoadingButton>
                                </Grid>
                            </Grid>
                        </Box>
                    </CardContent>
                </Card>
            </Grid>
        </Grid>
    );
}
