import {
    TableContainer,
    Paper,
    Table,
    TableHead,
    TableRow,
    TableBody,
    Tooltip,
    Grid,
    Typography,
    Button,
} from "@mui/material";
import { useEffect, useState } from "react";
import { Queue, Status } from "../../app/models/queue";
import agent from "../../app/api/agent";
import OutdoorGrillIcon from "@mui/icons-material/OutdoorGrill";
import { diff_minutes } from "../../app/extentions/WaitingTime";
import AlarmOnIcon from "@mui/icons-material/AlarmOn";
import ClearIcon from "@mui/icons-material/Clear";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import {
    StyledTableCell,
    StyledTableRow,
    adminTableFontSize,
} from "../../app/style/customStyle";
import UndoIcon from "@mui/icons-material/Undo";
import LoadingComponent from "../../app/layout/LoadingComponent";
import { displayPhoneNumber } from "../../app/configuration/phoneNumberConvertor";
import { useNavigate, useParams } from "react-router-dom";
import { BBQrestaurants } from "../../app/configuration/restaurant";
import { customLightBlue, lightRed } from "../../app/style/customColor";
import { Guid } from "guid-typescript";
import { useAppSelector } from "../../app/store/configureStore";

export default function HistoryPage() {
    const [queues, setQueues] = useState<Queue[]>([]);
    const [updateQueue, setUpdateQueue] = useState(false);
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate();
    const { user } = useAppSelector((state) => state.account);
    const { restaurantParam } = useParams<{ restaurantParam: string }>();

    useEffect(() => {
        agent.Display.getArchived(restaurantParam!).then((res) => {
            setQueues(res);
            setLoading(false);
        });
    }, []); //eslint-disable-line

    useEffect(() => {
        agent.Display.getArchived(restaurantParam!).then((res) => {
            setQueues(res);
        });
    }, [updateQueue]); //eslint-disable-line

    const putBackToAdmin = (id: Guid) => {
        agent.Display.updateStatusByAdmin({
            id: id,
            status: Status.Waiting,
            restaurantName: restaurantParam,
        }).then(() => {
            setUpdateQueue(!updateQueue);
            user?.roles?.includes("Admin") &&
                navigate(`/${restaurantParam}/admin`);
        });
    };

    const getRowBackgroundColor = (diff: number) => {
        if (diff >= 60) return lightRed;
        else return "";
    };

    if (loading) return <LoadingComponent message="Loading..." />;

    return (
        <TableContainer component={Paper}>
            <Table
                sx={{ minWidth: 650 }}
                size="small"
                stickyHeader
                aria-label="sticky table"
            >
                <TableHead>
                    <TableRow>
                        <StyledTableCell
                            align="center"
                            sx={{
                                fontSize: adminTableFontSize,
                                fontWeight: "bold",
                            }}
                        >
                            Q
                        </StyledTableCell>
                        <StyledTableCell
                            align="center"
                            sx={{
                                fontSize: adminTableFontSize,
                                fontWeight: "bold",
                            }}
                        >
                            Name
                            <br />
                            Phone
                        </StyledTableCell>
                        <StyledTableCell
                            align="center"
                            sx={{
                                fontSize: adminTableFontSize,
                                fontWeight: "bold",
                            }}
                        >
                            Size
                        </StyledTableCell>
                        <StyledTableCell
                            align="center"
                            sx={{
                                fontSize: adminTableFontSize,
                                fontWeight: "bold",
                            }}
                        >
                            Kids
                        </StyledTableCell>
                        {BBQrestaurants.includes(
                            restaurantParam!.toLowerCase()
                        ) && (
                            <>
                                <StyledTableCell
                                    align="center"
                                    sx={{
                                        fontSize: adminTableFontSize,
                                        fontWeight: "bold",
                                    }}
                                >
                                    BBQ
                                </StyledTableCell>
                            </>
                        )}
                        <StyledTableCell
                            align="center"
                            sx={{
                                fontSize: adminTableFontSize,
                                fontWeight: "bold",
                            }}
                        >
                            Checked-in
                        </StyledTableCell>
                        <StyledTableCell
                            align="center"
                            sx={{
                                fontSize: adminTableFontSize,
                                fontWeight: "bold",
                            }}
                        >
                            <Grid
                                display="flex"
                                alignItems="center"
                                justifyContent="center"
                                item
                            >
                                Notification&nbsp;
                                <Tooltip title="Send 15 min SMS">
                                    <AlarmOnIcon
                                        fontSize="small"
                                        sx={{
                                            color: customLightBlue,
                                        }}
                                    />
                                </Tooltip>
                            </Grid>
                        </StyledTableCell>
                        <StyledTableCell
                            align="center"
                            sx={{
                                fontSize: adminTableFontSize,
                                fontWeight: "bold",
                            }}
                        >
                            <Grid
                                display="flex"
                                alignItems="center"
                                justifyContent="center"
                                item
                            >
                                Ready&nbsp;
                                <Tooltip title="Send Ready SMS">
                                    <CheckCircleIcon
                                        fontSize="small"
                                        color="success"
                                    />
                                </Tooltip>
                            </Grid>
                        </StyledTableCell>
                        <StyledTableCell
                            align="center"
                            sx={{
                                fontSize: adminTableFontSize,
                                fontWeight: "bold",
                            }}
                        >
                            <Grid
                                display="flex"
                                alignItems="center"
                                justifyContent="center"
                                item
                            >
                                Complete&nbsp;
                                <Tooltip title="Complete">
                                    <ClearIcon
                                        fontSize="small"
                                        sx={{ color: "black" }}
                                    />
                                </Tooltip>
                            </Grid>
                        </StyledTableCell>
                        <StyledTableCell
                            align="center"
                            sx={{
                                fontSize: adminTableFontSize,
                                fontWeight: "bold",
                                maxWidth: "200px",
                            }}
                        >
                            Status
                            <br />
                            Note
                        </StyledTableCell>
                        <StyledTableCell
                            align="center"
                            sx={{
                                fontSize: adminTableFontSize,
                                fontWeight: "bold",
                            }}
                        >
                            {new Date().toISOString().split("T")[0]}
                            <br />
                            Total Waiting Time
                        </StyledTableCell>
                        <StyledTableCell
                            align="center"
                            sx={{
                                fontSize: adminTableFontSize,
                                fontWeight: "bold",
                            }}
                        >
                            Undo
                        </StyledTableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {queues.map((row, idx) => (
                        <StyledTableRow
                            key={idx}
                            style={{
                                backgroundColor: getRowBackgroundColor(
                                    diff_minutes(
                                        new Date(row.readyTime).getTime() > 0
                                            ? row.readyTime
                                            : row.archivedTime,
                                        row.createdTime
                                    )
                                ),
                            }}
                        >
                            <StyledTableCell
                                align="center"
                                sx={{ fontSize: 15 }}
                            >
                                {idx + 1}
                                <br />
                                {row.id
                                    .toString()
                                    .substring(row.id.toString().length - 4)}
                            </StyledTableCell>
                            <StyledTableCell
                                align="center"
                                sx={{ fontSize: 15 }}
                            >
                                {row.userName}
                                <br />
                                {displayPhoneNumber(row.phoneNumber)}
                            </StyledTableCell>
                            <StyledTableCell
                                align="center"
                                sx={{ fontSize: 15 }}
                            >
                                {row.size}
                            </StyledTableCell>
                            <StyledTableCell
                                align="center"
                                sx={{ fontSize: 15 }}
                            >
                                {row.sizeKids}
                            </StyledTableCell>

                            {BBQrestaurants.includes(
                                restaurantParam!.toLowerCase()
                            ) && (
                                <>
                                    <StyledTableCell
                                        align="center"
                                        sx={{ fontSize: 15 }}
                                    >
                                        {row.isBbq && (
                                            <OutdoorGrillIcon fontSize="medium" />
                                        )}
                                    </StyledTableCell>
                                </>
                            )}
                            <StyledTableCell
                                align="center"
                                sx={{ fontSize: 15 }}
                            >
                                {new Date(row.createdTime).toLocaleTimeString(
                                    [],
                                    {
                                        hour: "2-digit",
                                        minute: "2-digit",
                                        second: "2-digit",
                                    }
                                )}
                            </StyledTableCell>
                            <StyledTableCell
                                align="center"
                                sx={{ fontSize: 15 }}
                            >
                                {new Date(row.notifiedTime).getTime() > 0
                                    ? new Date(
                                          row.notifiedTime
                                      ).toLocaleTimeString([], {
                                          hour: "2-digit",
                                          minute: "2-digit",
                                          second: "2-digit",
                                      })
                                    : "X"}
                            </StyledTableCell>
                            <StyledTableCell
                                align="center"
                                sx={{ fontSize: 15 }}
                            >
                                {new Date(row.readyTime).getTime() > 0
                                    ? new Date(
                                          row.readyTime
                                      ).toLocaleTimeString([], {
                                          hour: "2-digit",
                                          minute: "2-digit",
                                          second: "2-digit",
                                      })
                                    : "X"}
                            </StyledTableCell>
                            <StyledTableCell
                                align="center"
                                sx={{ fontSize: 15 }}
                            >
                                {new Date(row.archivedTime).toLocaleTimeString(
                                    [],
                                    {
                                        hour: "2-digit",
                                        minute: "2-digit",
                                        second: "2-digit",
                                    }
                                )}
                            </StyledTableCell>
                            <StyledTableCell
                                align="center"
                                sx={{ fontSize: 15, maxWidth: "200px" }}
                            >
                                {Status[row.status]}
                                <br />
                                <Typography
                                    variant="caption"
                                    display="block"
                                    gutterBottom
                                >
                                    {row.note}
                                </Typography>
                            </StyledTableCell>
                            <StyledTableCell
                                align="center"
                                sx={{ fontSize: 15 }}
                            >
                                {diff_minutes(
                                    new Date(row.readyTime).getTime() > 0
                                        ? row.readyTime
                                        : row.archivedTime,
                                    row.createdTime
                                )}{" "}
                                Minutes
                            </StyledTableCell>
                            <StyledTableCell
                                align="center"
                                sx={{ fontSize: 15 }}
                            >
                                <Button onClick={() => putBackToAdmin(row.id)}>
                                    <UndoIcon
                                        fontSize="large"
                                        sx={{
                                            color: customLightBlue,
                                        }}
                                    />
                                </Button>
                            </StyledTableCell>
                        </StyledTableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
}
