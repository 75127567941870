export const guestMsg = {
    GuestDetailMessage: {
        title: {
            waiting: "Your are in the queue",
            notified: "You are notified",
            ready: "Your table is ready now",
            cancel: "You leave the queue",
            complete: "Your queue is ended",
            noshow: "Your queue is expired",
        },
        content: {
            footerEnglish:
                "If you make a mistake with your information details, please reach out to our server. Please Note that we can only hold the table for 5 minutes after it's ready. The seating orders are subject to change according to the restaurant's policy.",
            // TODO: custom language
                footerKorean:
                "",
        },
        expectedWaitTime: {
            English: "It may differ from the actual wait time.",
            // TODO: custom language
            Korean: "",
        },
    },
};
