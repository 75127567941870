import { Outlet, useParams } from "react-router-dom";
import { useCallback, useEffect, useState } from "react";
import Header from "./Header";
import { fetchCurrentUser } from "../../features/account/accountSlice";
import { useAppDispatch, useAppSelector } from "../store/configureStore";
import { Container } from "@mui/material";
import LoadingComponent from "./LoadingComponent";
import HomePage from "./HomePage";
import { restaurant } from "../configuration/restaurant";

export default function App() {
    const { restaurantParam } = useParams<{ restaurantParam: string }>();
    const { user } = useAppSelector((state) => state.account);
    const dispatch = useAppDispatch();
    const [loading, setLoading] = useState(true);
    const initApp = useCallback(async () => {
        try {
            await dispatch(fetchCurrentUser());
        } catch (error) {
            console.log(error);
        }
    }, [dispatch]);

    useEffect(() => {
        initApp().then(() => setLoading(false));
    }, [initApp]);

    return (
        <>
            <Header />
            <Container
                sx={{
                    pt: "20px",
                    pl: "5px",
                    pr: "5px",
                    display: "block",
                    margin: "auto",
                }}
                disableGutters={true}
                maxWidth="xl"
            >
                {loading ? (
                    <LoadingComponent message="Initialiszing app" />
                ) : restaurant.includes(restaurantParam!.toLowerCase()) &&
                  user &&
                  user.restaurants!.some(
                      (element) =>
                          element.restaurantName.toLowerCase() ===
                          restaurantParam?.toLowerCase()
                  ) &&
                  window.location.pathname === `/${restaurantParam}` ? (
                    <HomePage />
                ) : (
                    <Container sx={{ p: 0 }}>
                        <Outlet />
                    </Container>
                )}
            </Container>
        </>
    );
}
