import { Container, Grid, Paper, Typography } from "@mui/material";
import FactCheckIcon from "@mui/icons-material/FactCheck";
import ManageHistoryIcon from "@mui/icons-material/ManageHistory";
import ConnectedTvIcon from "@mui/icons-material/ConnectedTv";
import { cobaltBlue } from "../../style/customColor";
import ListContext from "./ListContext";

export default function SolutionPage() {
    const solutionData = [
        {
            title: "User Integration Focused Design",
            context: `
            <li>User-Friendly Interface: Our platform features a user-friendly interface accessible to all users.</li>
            <li>Display Tab: Experience real-time updates with the display screen, allowing your customers to check their waitlist status at a single glance.</li>
            <li>Admin Tab: Take control of your waitlists with the admin page, where you can manage all your waitlists from one screen.</li>
            <li>History Tab: Made a mistake? No worries! You can always go back to the admin page for corrections.</li>
            `,
        },
    ];
    return (
        <Container
            sx={{
                pt: "0px",
                pl: "5px",
                pr: "5px",
                display: "block",
                margin: "auto",
            }}
            disableGutters={true}
            maxWidth="lg"
        >
            <Grid container padding={0} marginBottom="5rem">
                <Grid
                    item
                    xs={12}
                    lg={4}
                    sx={{
                        justifyContent: "center",
                        alignItems: "center",
                        margin: "auto",
                    }}
                >
                    <img
                        src={`/images/mainpage/solution.jpg`}
                        style={{
                            display: "block",
                            width: "100%",
                            maxWidth: "1000px",
                            height: "auto",
                            marginBottom: "2rem",
                        }}
                        alt="Guest page"
                    />
                </Grid>
                <Grid item xs={12} lg={8} sx={{ paddingLeft: { md: "2rem" } }}>
                    <ListContext
                        mainTitle="Solution"
                        aboutArray={solutionData}
                    />
                </Grid>
            </Grid>

            <Paper
                sx={{
                    padding: "0.5rem",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    boxShadow: 0,
                    backgroundColor: "whitesmoke",
                }}
            >
                <div
                    style={{
                        display: "flex",
                        alignItems: "center",
                        margin: "2rem",
                    }}
                >
                    <ConnectedTvIcon
                        fontSize="medium"
                        sx={{
                            color: cobaltBlue,
                            marginRight: "0.5rem",
                        }}
                    />
                    <span style={{ fontSize: "1.3rem" }}>
                        <strong>Display tab</strong>
                    </span>
                </div>
                <img
                    src={`/images/mainpage/Screenshot/DisplayPage.png`}
                    style={{
                        display: "block",
                        width: "100%",
                        maxWidth: "1000px",
                        height: "auto",
                        marginBottom: "2rem",
                    }}
                    alt="Guest page"
                />
            </Paper>

            <Typography variant="body1" textAlign="center">
                Monitor the waiting status on the display screen.
                <br />
                All users are also directed to the status details page on their
                mobile phones to check their wait status.
            </Typography>
            <hr
                color="lightgrey"
                style={{ width: "80%", marginBottom: "2rem" }}
            />

            <Paper
                sx={{
                    padding: "0.5rem",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    boxShadow: 0,
                    margin: "auto",
                    backgroundColor: "whitesmoke",
                }}
            >
                <div
                    style={{
                        display: "flex",
                        alignItems: "center",
                        margin: "2rem",
                    }}
                >
                    <FactCheckIcon
                        fontSize="medium"
                        sx={{
                            color: cobaltBlue,
                            marginRight: "0.5rem",
                        }}
                    />
                    <span style={{ fontSize: "1.3rem" }}>
                        <strong>Admin tab</strong>
                    </span>
                </div>
                <img
                    src={`/images/mainpage/Screenshot/AdminPage.png`}
                    style={{
                        display: "block",
                        width: "100%",
                        maxWidth: "1000px",
                        height: "auto",
                        marginBottom: "2rem",
                    }}
                    alt="Guest page"
                />
            </Paper>
            <Typography variant="body1" textAlign="center">
                <br />
                With QueuingMe's product, you can efficiently manage all your
                waitlist in real-time. We utilize the latest technology to make
                this possible with just a few clicks.
                <br />
                Transform the way you handle waitlist. Our user-first solution
                simplifies your workflow and enhances the dining experience.
                Take control of your restaurant's future today!
            </Typography>
            <hr
                color="lightgrey"
                style={{ width: "80%", marginBottom: "2rem" }}
            />
            <Paper
                sx={{
                    padding: "0.5rem",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    boxShadow: 0,
                    margin: "auto",
                    backgroundColor: "whitesmoke",
                }}
            >
                <div
                    style={{
                        display: "flex",
                        alignItems: "center",
                        margin: "2rem",
                    }}
                >
                    <ManageHistoryIcon
                        fontSize="medium"
                        sx={{
                            color: cobaltBlue,
                            marginRight: "0.5rem",
                        }}
                    />
                    <span style={{ fontSize: "1.3rem" }}>
                        <strong>History tab</strong>
                    </span>
                </div>
                <img
                    src={`/images/mainpage/Screenshot/HistoryPage.png`}
                    style={{
                        display: "block",
                        width: "100%",
                        maxWidth: "1000px",
                        height: "auto",
                        marginBottom: "2rem",
                    }}
                    alt="Guest page"
                />
            </Paper>

            <Typography variant="body1" textAlign="center">
                Access a comprehensive log of completed waitlist, including all
                the details.
                <br />
                Navigate through a timeline of restaurant guests who have been
                successfully served, in case you need it.
            </Typography>
            <hr color="lightgrey" style={{ width: "80%" }} />
        </Container>
    );
}
