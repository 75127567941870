import {
    Container,
    Grid,
    List,
    ListItem,
    ListItemButton,
    ListItemText,
    Paper,
    Typography,
} from "@mui/material";
import InsertChartIcon from "@mui/icons-material/InsertChart";
import StackedLineChartIcon from "@mui/icons-material/StackedLineChart";
import PieChartIcon from "@mui/icons-material/PieChart";
import HourglassBottomIcon from "@mui/icons-material/HourglassBottom";
import { useState } from "react";
import QueueStat from "./Statistics/QueueStat";
import SizeStat from "./Statistics/SizeStat";
import StatusStat from "./Statistics/StatusStat";
import WaitTimeStat from "./Statistics/WaitTimeStat";

export default function StatisticsPage() {
    const [selectedComponent, setSelectedComponent] = useState("Queue");
    const [selectedIndex, setSelectedIndex] = useState(1);

    const handleItemClick = (componentName: string, index: number) => {
        setSelectedComponent(componentName);
        setSelectedIndex(index);
    };

    const renderComponent = () => {
        switch (selectedComponent) {
            case "Queue":
                return <QueueStat />;
            case "Status":
                return <StatusStat />;
            case "Size":
                return <SizeStat />;
            case "WaitTime":
                return <WaitTimeStat />;
            default:
                return <QueueStat />;
        }
    };

    return (
        <Paper>
            <Container sx={{ p: 2, minHeight: "600px" }}>
                <Grid container spacing={2}>
                    <Grid item xs={2}>
                        <nav aria-label="main mailbox folders">
                            <List>
                                <ListItem
                                    disablePadding
                                    onClick={() => handleItemClick("Queue", 1)}
                                >
                                    <ListItemButton
                                        selected={selectedIndex === 1}
                                    >
                                        <StackedLineChartIcon />
                                        <ListItemText
                                            primary="Queue"
                                            sx={{
                                                display: {
                                                    xs: "none",
                                                    sm: "block",
                                                },
                                                marginLeft: 1,
                                            }}
                                        />
                                    </ListItemButton>
                                </ListItem>
                                <ListItem
                                    disablePadding
                                    onClick={() => handleItemClick("Status", 2)}
                                >
                                    <ListItemButton
                                        selected={selectedIndex === 2}
                                    >
                                        <PieChartIcon />
                                        <ListItemText
                                            primary="Status"
                                            sx={{
                                                display: {
                                                    xs: "none",
                                                    sm: "block",
                                                },
                                                marginLeft: 1,
                                            }}
                                        />
                                    </ListItemButton>
                                </ListItem>
                                <ListItem
                                    disablePadding
                                    onClick={() => handleItemClick("Size", 3)}
                                >
                                    <ListItemButton
                                        selected={selectedIndex === 3}
                                    >
                                        <InsertChartIcon />
                                        <ListItemText
                                            primary="Size"
                                            sx={{
                                                display: {
                                                    xs: "none",
                                                    sm: "block",
                                                },
                                                marginLeft: 1,
                                            }}
                                        />
                                    </ListItemButton>
                                </ListItem>
                                <ListItem
                                    disablePadding
                                    onClick={() =>
                                        handleItemClick("WaitTime", 4)
                                    }
                                >
                                    <ListItemButton
                                        selected={selectedIndex === 4}
                                    >
                                        <HourglassBottomIcon />
                                        <ListItemText
                                            primary="Wait Time"
                                            sx={{
                                                display: {
                                                    xs: "none",
                                                    sm: "block",
                                                },
                                                marginLeft: 1,
                                            }}
                                        />
                                    </ListItemButton>
                                </ListItem>
                            </List>
                        </nav>
                    </Grid>
                    <Grid item xs={10}>
                        {renderComponent()}
                    </Grid>
                </Grid>
            </Container>
        </Paper>
    );
}
