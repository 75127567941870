import styled from "@emotion/styled";
import { TableCell, tableCellClasses, TableRow } from "@mui/material";
import {
    dashBoardTableHeader,
    dashBoardTableHeaderFont,
    oddRows,
    tableHeader,
} from "./customColor";

export const StyledTableCell = styled(TableCell)(() => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: tableHeader,
        color: "black",
        padding: adminTablePadding,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: adminTableFontSize,
        padding: adminTablePadding,
    },
}));

export const StyledTableRow = styled(TableRow)(() => ({
    "&:nth-of-type(odd)": {
        backgroundColor: oddRows,
    },
    // hide last border
    "&:last-child td, &:last-child th": {
        border: 0,
    },
}));

export const StyledDashBoardTableCell = styled(TableCell)(() => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: dashBoardTableHeader,
        color: dashBoardTableHeaderFont,
        fontSize: dashBoardFontSize,
        textAlign: "center",
        padding: "15px",
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: dashBoardFontSize,
        padding: dashBoardPaddingSize,
        textAlign: "center",
    },
}));

export const StyledDashBoardTableRow = styled(TableRow)(() => ({
    "&:nth-of-type(odd)": {
        backgroundColor: oddRows,
    },
    // hide last border
    "&:last-child td, &:last-child th": {
        border: 0,
    },
}));

export const dashBoardFontSize = "2.5rem";
export const dashBoardStatusFontSize = "2.5rem";
export const dashBoardPaddingSize = "5px";
export const dashBoardStatusButtonWidth = "300px";
export const adminTableFontSize = 15;
export const adminTablePadding = 5;
export const adminTableFirstColumnWidth = "30px";
export const adminTableSecondColumnWidth = "120px";
export const adminTableEditColumnWidth = "30px";
export const adminTableSizeColumnWidth = "30px";
export const adminTableBbqColumnWidth = "30px";
export const adminTableCheckInColumnWidth = "140px";
export const adminTableButtonColumnWidth = "200px";
