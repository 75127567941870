import {
    AppBar,
    Toolbar,
    Box,
    List,
    ListItem,
    Button,
    Container,
} from "@mui/material";
import { NavLink, useParams } from "react-router-dom";
import { useAppSelector } from "../store/configureStore";
import SignedInMenu from "./SignedInMenu";
import ConnectedTvIcon from "@mui/icons-material/ConnectedTv";
import HistoryIcon from "@mui/icons-material/History";
import InsertChartIcon from "@mui/icons-material/InsertChart";
import ChecklistIcon from '@mui/icons-material/Checklist';

export const navStyle = {
    color: "inherit",
    textDecoration: "none",
    typography: "h6",
    "&:hover": {
        color: "grey.500",
    },
    "&.active": {
        color: "text.secondary",
    },
};

export default function Header() {
    const { user } = useAppSelector((state) => state.account);
    const { restaurantParam } = useParams<{ restaurantParam: string }>();

    return (
        <AppBar position="static">
            <Container maxWidth="lg">
                <Toolbar
                    sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                    }}
                >
                    <>
                        {user && !user.roles?.includes("SuperAdmin") && (
                            <>
                                <Box display="flex" alignItems="center">
                                    <Button
                                        component={NavLink}
                                        to={`/${restaurantParam}/guest`}
                                    >
                                        <img
                                            src={`/images/${restaurantParam}Icon.png`}
                                            style={{
                                                display: "block",
                                                maxHeight: "40px",
                                                maxWidth: "100px",
                                            }}
                                            alt="logo"
                                        />
                                    </Button>
                                </Box>

                                <List sx={{ display: "flex" }}>
                                    {[
                                        {
                                            title: "Display",
                                            path: `/${restaurantParam}/display`,
                                        },
                                    ].map(({ title, path }) => (
                                        <ListItem
                                            component={NavLink}
                                            to={path}
                                            key={path}
                                            sx={{...navStyle, padding: { xs: "8px 12px", md: "8px 16px" }}}
                                        >
                                            <ConnectedTvIcon sx={{fontSize: {xs: "1em", md: "1.5em"}}}/>
                                        </ListItem>
                                    ))}
                                    {user && user.roles?.includes("Admin") && (
                                        <>
                                            <ListItem
                                                component={NavLink}
                                                to={`/${restaurantParam}/Admin`}
                                                sx={{...navStyle, padding: { xs: "8px 12px", md: "8px 16px" }}}
                                            >
                                                <ChecklistIcon sx={{fontSize: {xs: "1em", md: "1.5em"}}}/>
                                            </ListItem>

                                            <ListItem
                                                component={NavLink}
                                                to={`/${restaurantParam}/History`}
                                                sx={{...navStyle, padding: { xs: "8px 12px", md: "8px 16px" }}}
                                            >
                                                <HistoryIcon sx={{fontSize: {xs: "1em", md: "1.5em"}}}/>
                                            </ListItem>

                                            <ListItem
                                                component={NavLink}
                                                to={`/${restaurantParam}/Statistics`}
                                                sx={{...navStyle, padding: { xs: "8px 12px", md: "8px 16px" }}}
                                            >
                                                <InsertChartIcon sx={{fontSize: {xs: "1em", md: "1.5em"}}}/>
                                            </ListItem>
                                        </>
                                    )}
                                </List>
                                <Box display="flex" alignItems="center">
                                    {user ? (
                                        <SignedInMenu />
                                    ) : (
                                        <List sx={{ display: "flex" }}>
                                            {[
                                                {
                                                    title: "Login",
                                                    path: `/${restaurantParam}/login`,
                                                },
                                                {
                                                    title: "Register",
                                                    path: `/${restaurantParam}/register`,
                                                },
                                            ].map(({ title, path }) => (
                                                <ListItem
                                                    component={NavLink}
                                                    to={path}
                                                    key={path}
                                                    sx={navStyle}
                                                >
                                                    {title}
                                                </ListItem>
                                            ))}
                                        </List>
                                    )}
                                </Box>
                            </>
                        )}

                        {user && user.roles?.includes("SuperAdmin") && (
                            <Box display="flex" alignItems="center">
                                <List sx={{ display: "flex" }}>
                                    <ListItem
                                        component={NavLink}
                                        to={`/${restaurantParam}/DisplayAll`}
                                        sx={{...navStyle, padding: { xs: "8px 12px", md: "8px 16px" }}}
                                    >
                                        <HistoryIcon />
                                    </ListItem>
                                </List>
                            </Box>
                        )}
                    </>
                </Toolbar>
            </Container>
        </AppBar>
    );
}
