import Avatar from "@mui/material/Avatar";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { Paper } from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { LoadingButton } from "@mui/lab";
import agent from "../../app/api/agent";

export default function Register() {
    const {
        register,
        handleSubmit,
        setError,
        formState: { isSubmitting, errors, isValid },
    } = useForm({
        mode: "all",
    });
    const navigate = useNavigate();
    function handlerApiErrors(errors: any) {
        if (errors) {
            errors.forEach((error: string) => {
                if (error.includes("Password")) {
                    setError("password", { message: error });
                } else if (error.includes("Email")) {
                    setError("email", { message: error });
                } else if (error.includes("Username")) {
                    setError("username", { message: error });
                }
            });
        }
        console.log(errors);
    }

    return (
        <Container
            component={Paper}
            maxWidth="sm"
            sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                mt: "4rem",
                p: 4,
                boxShadow: 10,
                width: "95%"
            }}
        >
            <img
                src={`/images/QueuingMeLogo.png`}
                width="130px"
                style={{ margin: "auto" }}
                alt="logo"
            />
            <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
                <LockOutlinedIcon />
            </Avatar>
            <Typography component="h1" variant="h5">
                Register
            </Typography>
            <Box
                component="form"
                onSubmit={handleSubmit((data) =>
                    agent.Account.register(data)
                        .then(() => {
                            navigate(`/login`);
                        })
                        .catch((error) => handlerApiErrors(error))
                )}
                noValidate
                sx={{ mt: 1 }}
            >
                <TextField
                    margin="normal"
                    required
                    fullWidth
                    label="User name"
                    autoComplete="email"
                    autoFocus
                    {...register("username", {
                        required: "Username is required",
                    })}
                    error={!!errors.username}
                    helperText={errors?.username?.message?.toString()}
                />
                <TextField
                    margin="normal"
                    required
                    fullWidth
                    label="Email address"
                    autoComplete="email"
                    {...register("email", {
                        required: "Email is required",
                        pattern: {
                            value: /^\w+[\w-.]*@\w+((-\w+)|(\w*))\.[a-z]{2,3}$/,
                            message: "Not a valid email address",
                        },
                    })}
                    error={!!errors.email}
                    helperText={errors?.email?.message?.toString()}
                />
                <TextField
                    margin="normal"
                    fullWidth
                    label="Password"
                    type="password"
                    autoComplete="current-password"
                    {...register("password", {
                        required: "Password is required",
                        pattern: {
                            value: /(?=^.{6,16}$)(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&amp;*()_+}{&quot;:;'?/&gt;.&lt;,])(?!.*\s).*$/,
                            message:
                                "Password expects atleast 1 small-case letter, 1 Capital letter, 1 digit, 1 special character and the length should be between 6-16 characters.",
                        },
                    })}
                    error={!!errors.password}
                    helperText={errors?.password?.message?.toString()}
                />
                <LoadingButton
                    loading={isSubmitting}
                    disabled={!isValid}
                    type="submit"
                    fullWidth
                    variant="contained"
                    sx={{ mt: 3, mb: 2 }}
                >
                    Register
                </LoadingButton>
                <Grid container>
                    <Grid item>
                        <Link to={`/login`}>
                            <Typography variant="body1">
                                Already have an account? Sign In
                            </Typography>
                        </Link>
                    </Grid>
                </Grid>
            </Box>
        </Container>
    );
}
