import { Container, Grid, Typography, Divider } from "@mui/material";
import { primaryColor } from "../../../app/style/customColor";
import TroubleshootIcon from "@mui/icons-material/Troubleshoot";

export default function TroubleShoot() {
    return (
        <Container>
            <Grid container marginTop={3}>
                <TroubleshootIcon fontSize="large" color="primary" />
                <Typography
                    variant="h5"
                    fontWeight="bold"
                    color={primaryColor}
                    sx={{ marginLeft: 1 }}
                >
                    Trouble Shoot
                </Typography>
            </Grid>
            <Divider />
            <Typography variant="h6" fontWeight="bold" marginTop={3}>
                Please check your internet connection first before attempting
                any troubleshooting steps below.
            </Typography>
            <Typography variant="h6" fontWeight="bold" marginTop={3}>
                A. Display Page Refresh Issue
            </Typography>
            <Typography variant="body1">
                1. Refresh the page.
                <br />
                2. Try using the displayAPI page instead of the display page.
            </Typography>
            <Typography variant="body2">
                &nbsp;&nbsp;&nbsp;&nbsp;https://www.queuingme.com/RESTAURANTNAME/public/display
                - Realtime(Recommended)
                &nbsp;&nbsp;&nbsp;&nbsp;https://www.queuingme.com/RESTAURANTNAME/public/displayAPI
                - Refresh every 5 seconds
            </Typography>

            <Typography variant="subtitle2" sx={{ fontStyle: "italic" }}>
                <br />* The page refresh issue on the display page was resolved
                on April 5, 2024.
            </Typography>
            <Divider />
        </Container>
    );
}
