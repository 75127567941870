import { useState } from "react";
import agent from "../../../app/api/agent";
import { useParams } from "react-router-dom";
import { useAppSelector } from "../../../app/store/configureStore";
import { LoadingButton } from "@mui/lab";
import { Box, Container, Divider, Grid, TextField, Typography } from "@mui/material";
import { useForm, FieldValues } from "react-hook-form";
import { primaryColor } from "../../../app/style/customColor";
import PasswordIcon from "@mui/icons-material/Password";

const PasswordResetForm = () => {
    const { user } = useAppSelector((state) => state.account);
    const [isLoading, setIsLoading] = useState(false);
    const { restaurantParam } = useParams<{ restaurantParam: string }>();
    const {
        register,
        handleSubmit,
        formState: { errors, isValid, isSubmitting },
    } = useForm({
        mode: "onTouched",
    });
    async function submitForm(data: FieldValues) {
        setIsLoading(true);
        try {
            await agent.PasswordReset.requestReset(data);
        } catch (error) {
            console.error(error);
        } finally {
            setIsLoading(false);
        }
    }

    return (
        
        <Container>
            <Grid container marginTop={3}>
                <PasswordIcon fontSize="large" color="primary" />
                <Typography variant="h5" fontWeight="bold" color={primaryColor} sx={{marginLeft: 1}}>
                    Password Change
                </Typography>
            </Grid>
            <Divider />
            <Box
                component="form"
                onSubmit={handleSubmit(submitForm)}
                noValidate
                sx={{ mt: 3 }}
            >
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            margin="normal"
                            fullWidth
                            label="Email Address"
                            autoComplete="email"
                            value={user?.email}
                            variant="standard"
                            {...register("email", {
                                required: "Email is required",
                                pattern: {
                                    value: /^\w+[\w-.]*@\w+((-\w+)|(\w*))\.[a-z]{2,3}$/,
                                    message: "Not a valid email address",
                                },
                            })}
                            error={!!errors.email}
                            helperText={errors?.email?.message?.toString()}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            margin="normal"
                            fullWidth
                            label="Restaurant Name"
                            value={restaurantParam}
                            variant="standard"
                            {...register("restaurantName", {
                                required: "Restaurant is required",
                            })}
                            error={!!errors.restaurantName}
                            helperText={errors?.restaurantName?.message?.toString()}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            margin="normal"
                            fullWidth
                            label="User Name"
                            value={user!.userName}
                            variant="standard"
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            label="Current Password"
                            type="password"
                            {...register("password", {
                                required: "Password is required",
                            })}
                            error={!!errors.password}
                            helperText={errors?.password?.message as string}
                        />
                    </Grid>
                    <Grid item xs={12} sm={12}>
                        <LoadingButton
                            disabled={!isValid}
                            loading={isSubmitting}
                            type="submit"
                            fullWidth
                            variant="contained"
                            sx={{ mt: 3, mb: 2 }}
                        >
                            Send Password Reset Email
                        </LoadingButton>
                    </Grid>
                </Grid>

                <div style={{ height: "0px", overflow: "hidden" }}>
                    <TextField
                        hidden={true}
                        value={window.location.href}
                        {...register("sourceUrl")}
                    />
                </div>
            </Box>
            <Typography variant="body2">
                * A password reset link will be sent to your email address<br/>
                * If a user has two or more restaurants under the same account, the password change will apply to all other restaurants
            </Typography>
        </Container>
    );
};

export default PasswordResetForm;
