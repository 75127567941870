import { Typography } from "@mui/material";

interface Props {
    restaurantName: string;
    bottom: number;
    right: number;
    size: number;
}

export default function QRcode({ restaurantName, bottom, right, size }: Props) {
    return (
        <div
            style={{
                position: "fixed",
                minWidth: "200px",
                width: `${size}%`,
                maxWidth: "500px",
                zIndex: 999,
                bottom: `${bottom}%`,
                right: `${right}%`
            }}
        >
            <Typography
                pt={1}
                variant="h6"
                sx={{
                    backgroundColor: "white",
                    color: "black",
                    textAlign: "center",
                    fontWeight: "bold",
                }}
            >
                Scan QR to join
            </Typography>
            <img
                src={"/images/" + restaurantName + "QR.png"}
                width="100%"
                alt="QRcode"
            />
        </div>
    );
}
