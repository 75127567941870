import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
} from "chart.js";
import { Line } from "react-chartjs-2";
import Holiday from "date-holidays";
import { useEffect, useState } from "react";
import { QueueStatObj, QueueXandY } from "../../../app/models/stat";
import agent from "../../../app/api/agent";
import { useParams } from "react-router-dom";
import { Container, Skeleton } from "@mui/material";

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
);

const dateLabels = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
];

const graphLineColor = [
    {
        borderColor: "rgb(255, 99, 132)",
        backgroundColor: "rgba(255, 99, 132, 0.5)",
    },
    {
        borderColor: "rgb(53, 162, 235)",
        backgroundColor: "rgba(53, 162, 235, 0.5)",
    },
    {
        borderColor: "rgb(255, 205, 86)",
        backgroundColor: "rgba(255, 205, 86, 0.5)",
    },
    {
        borderColor: "rgb(46, 204, 113)",
        backgroundColor: "rgba(46, 204, 113, 0.5)",
    },
];

export const options = {
    responsive: true,
    plugins: {
        legend: {
            position: "top" as const,
        },
        title: {
            display: true,
            text: "Weekly Waitlist Report",
        },
    },
    scales: {
        y: {
            beginAtZero: true,
            type: "linear" as const,
            ticks: {
                stepSize: 1,
            },
        },
    },
    elements: {
        point: {
            radius: 5,
            hoverRadius: 20,
        },
    },
};

interface HolidayData {
    name: string;
    date: string;
}

const holidays = new Holiday("CA", "BC");
const year = new Date().getFullYear() - 1;
const bcHolidays: HolidayData[] = holidays.getHolidays(year);

// Print the holidays
// console.log(`Public holidays in BC, Canada for ${year}`);
bcHolidays.forEach((holiday: HolidayData) => {
    var d = new Date(holiday.date);
    d.setDate(d.getDate() - d.getDay());
});

interface GraphData {
    label: string[];
    queue: QueueXandY[];
}

export default function QueueStat() {
    const { restaurantParam } = useParams<{ restaurantParam: string }>();
    const [data, setData] = useState<any>(null);
    const [graphData, setGraphData] = useState<GraphData[]>([]);
    const [loading, setLoading] = useState(true);

    const addGraphData = (newLabels: string[], newQueues: QueueXandY[]) => {
        const newGraphData: GraphData = {
            label: newLabels,
            queue: newQueues,
        };
        setGraphData((prevData) => [...prevData, newGraphData]);
    };

    const toWeeklyQueuesParamData = (date: Date) => {
        return {
            datetime: date.toISOString().split("T")[0],
            restaurantName: restaurantParam,
        };
    };

    const handleWeeklyQueues = async (date: Date) => {
        const weeklyParam = toWeeklyQueuesParamData(date);
        try {
            const queues = await agent.Statistics.getWeeklyQueues(
                weeklyParam
            );
            const newLabels: string[] = [];
            const newQueues: QueueXandY[] = [];
            queues.forEach((queue: QueueStatObj) => {
                const countDate = queue.date.toString().split("T")[0];
                const countDay = new Date(queue.date).toLocaleString(
                    "en-us",
                    { weekday: "long" }
                );
                newLabels.push(`${countDate} (${countDay})`);
                newQueues.push({
                    x: `${countDate} (${countDay})`,
                    y: queue.queueCount.toString(),
                });
            });
            addGraphData(newLabels, newQueues);
        } catch (error) {
            console.error("Error fetching weekly queues:", error);
        }
    };

    useEffect(() => {
        const fetchQueues = async () => {
            const today = new Date();
            const lastWeekDay = new Date(today);
            lastWeekDay.setDate(lastWeekDay.getDate() - 7);
            const lastYear = new Date(today);
            lastYear.setFullYear(today.getFullYear() - 1);

            await handleWeeklyQueues(today);
            await handleWeeklyQueues(lastWeekDay);
            await handleWeeklyQueues(lastYear);

            setLoading(false);
        };

        fetchQueues();
    }, []);

    useEffect(() => {
        if (graphData.length >= 4) {
            return;
        }
        if (graphData.length === 0) return;

        const count = graphData.length;
        const today = new Date();
        const lastWeek = new Date(today);
        lastWeek.setDate(today.getDate() - 7);
        const lastYear = new Date(today);
        lastYear.setFullYear(today.getFullYear() - 1);

        const todayFormatted = today.toLocaleDateString("en-CA", {
            year: "numeric",
            month: "2-digit",
            day: "2-digit",
        });
        const lastWeekFormatted = lastWeek.toLocaleDateString("en-CA", {
            year: "numeric",
            month: "2-digit",
            day: "2-digit",
        });
        const lastYearFormatted = lastYear.toLocaleDateString("en-CA", {
            year: "numeric",
            month: "2-digit",
            day: "2-digit",
        });

        setData({
            labels: dateLabels,
            datasets: graphData.map((data, index) => {
                let labelTitle = `Week of ${data.queue[0].x}`;
                let borderDash = [10, 10];

                const includesToday = data.queue.some((item) =>
                    item.x.split(" ")[0].includes(todayFormatted)
                );
                const includesLastWeek = data.queue.some((item) =>
                    item.x.split(" ")[0].includes(lastWeekFormatted)
                );
                const includesLastYear = data.queue.some((item) =>
                    item.x.split(" ")[0].includes(lastYearFormatted)
                );

                if (includesToday) {
                    labelTitle = "This week";
                    borderDash = [0, 0];
                } else if (includesLastWeek) {
                    labelTitle = "Last week";
                    borderDash = [5, 5];
                } else if (includesLastYear) {
                    var sundayDate = data.queue[0].x;
                    labelTitle = `Last year ${sundayDate}`;
                }

                return {
                    label: labelTitle,
                    data: data.queue.map((queue) => queue.y),
                    borderColor: graphLineColor[index % count].borderColor,
                    backgroundColor:
                        graphLineColor[index % count].backgroundColor,
                    borderDash: borderDash,
                };
            }),
        });
    }, [graphData]);

    if (data == null) {
        return <></>;
    }

    return (
        <Container sx={{ height: "100%" }}>
            {loading ? (
                <>
                    <Skeleton
                        variant="text"
                        width="20%"
                        sx={{
                            fontSize: "1rem",
                            display: "block",
                            margin: "auto",
                        }}
                    />
                    <Skeleton
                        variant="text"
                        width="40%"
                        sx={{
                            fontSize: "1rem",
                            display: "block",
                            margin: "auto",
                        }}
                    />
                    <br />
                    <Skeleton
                        variant="rounded"
                        width="90%"
                        height={500}
                        sx={{ display: "block", margin: "auto" }}
                    />
                </>
            ) : (
                <Line options={options} data={data} />
            )}
            {/* <Caldendar onDateSelect={handleDateSelect} /> //TODO: Add custom date */}
        </Container>
    );
}







// import {
//     Chart as ChartJS,
//     CategoryScale,
//     LinearScale,
//     PointElement,
//     LineElement,
//     Title,
//     Tooltip,
//     Legend,
// } from "chart.js";
// import { Line } from "react-chartjs-2";
// import Holiday from "date-holidays";
// import { useEffect, useState } from "react";
// import { QueueStatObj, QueueXandY } from "../../../app/models/stat";
// import agent from "../../../app/api/agent";
// import { useParams } from "react-router-dom";
// import { Container, Skeleton } from "@mui/material";
// import Caldendar from "./Calendar/Calendar";

// ChartJS.register(
//     CategoryScale,
//     LinearScale,
//     PointElement,
//     LineElement,
//     Title,
//     Tooltip,
//     Legend
// );

// const dateLabels = [
//     "Sunday",
//     "Monday",
//     "Tuesday",
//     "Wednesday",
//     "Thursday",
//     "Friday",
//     "Saturday",
// ];

// const graphLineColor = [
//     {
//         borderColor: "rgb(255, 99, 132)",
//         backgroundColor: "rgba(255, 99, 132, 0.5)",
//     },
//     {
//         borderColor: "rgb(53, 162, 235)",
//         backgroundColor: "rgba(53, 162, 235, 0.5)",
//     },
//     {
//         borderColor: "rgb(255, 205, 86)",
//         backgroundColor: "rgba(255, 205, 86, 0.5)",
//     },
//     {
//         borderColor: "rgb(46, 204, 113)",
//         backgroundColor: "rgba(46, 204, 113, 0.5)",
//     },
// ];

// export const options = {
//     responsive: true,
//     plugins: {
//         legend: {
//             position: "top" as const,
//         },
//         title: {
//             display: true,
//             text: "Weekly Waitlist Report",
//         },
//     },
//     scales: {
//         y: {
//             beginAtZero: true,
//             type: "linear" as const,
//             ticks: {
//                 stepSize: 1,
//             },
//         },
//     },
//     elements: {
//         point: {
//             radius: 5,
//             hoverRadius: 20,
//         },
//     },
// };

// interface HolidayData {
//     name: string;
//     date: string;
// }

// const holidays = new Holiday("CA", "BC");
// const year = new Date().getFullYear() - 1;
// const bcHolidays: HolidayData[] = holidays.getHolidays(year);

// // Print the holidays
// console.log(`Public holidays in BC, Canada for ${year}`);
// bcHolidays.forEach((holiday: HolidayData) => {
//     var d = new Date(holiday.date);
//     d.setDate(d.getDate() - d.getDay());
// });

// interface GraphData {
//     label: string[];
//     queue: QueueXandY[];
// }

// export default function QueueStat() {
//     const { restaurantParam } = useParams<{ restaurantParam: string }>();
//     const [data, setData] = useState<any>(null);
//     const [graphData, setGraphData] = useState<GraphData[]>([]);
//     const [loading, setLoading] = useState(true);
//     const [selectedDate, setSelectedDate] = useState<string | null>(null);
//     const [customeDateUpdated, setCustomeDateUpdated] = useState<boolean>(false);

//     const addGraphData = (newLabels: string[], newQueues: QueueXandY[]) => {
//         const newGraphData: GraphData = {
//             label: newLabels,
//             queue: newQueues,
//         };
//         setGraphData((prevData) => [...prevData, newGraphData]);
//     };

//     const toWeeklyQueuesParamData = (date: Date) => {
//         return {
//             datetime: date.toISOString().split("T")[0],
//             restaurantName: restaurantParam,
//         };
//     };

//     const handleDateSelect = (date: string) => {
//         setSelectedDate(date);
//     };

//     const toggleCustomeDateUpdated = () => {
//         setCustomeDateUpdated(prevState => !prevState);
//     };

//     const handleWeeklyQueues = async (date: Date) => {
//         const weeklyParam = toWeeklyQueuesParamData(date);
//         try {
//             const queues = await agent.Statistics.getWeeklyQueues(
//                 weeklyParam
//             );
//             const newLabels: string[] = [];
//             const newQueues: QueueXandY[] = [];
//             queues.forEach((queue: QueueStatObj) => {
//                 const countDate = queue.date.toString().split("T")[0];
//                 const countDay = new Date(queue.date).toLocaleString(
//                     "en-us",
//                     { weekday: "long" }
//                 );
//                 newLabels.push(`${countDate} (${countDay})`);
//                 newQueues.push({
//                     x: `${countDate} (${countDay})`,
//                     y: queue.queueCount.toString(),
//                 });
//             });
//             addGraphData(newLabels, newQueues);
//         } catch (error) {
//             console.error("Error fetching weekly queues:", error);
//         }
//     };

//     useEffect(() => {
//         const fetchQueues = async () => {
//             const today = new Date();
//             const lastWeekDay = new Date(today);
//             lastWeekDay.setDate(lastWeekDay.getDate() - 7);
//             // lastWeekDay.setDate(lastWeekDay.getDate() - 200); //TODO: remove custom set date before commit!!!!
//             const lastYear = new Date(today);
//             lastYear.setFullYear(today.getFullYear() - 1);
//             // lastYear.setDate(lastYear.getDate() + 14); //TODO: remove custom set date before commit!!!!
//             // today.setDate(today.getDate() - 180); //TODO: remove custom set date before commit!!!!

//             await handleWeeklyQueues(today);
//             await handleWeeklyQueues(lastWeekDay);
//             await handleWeeklyQueues(lastYear);

//             setLoading(false);
//         };

//         fetchQueues();
//     }, []);

//     useEffect(() => {
//         if (selectedDate === null) {
//             return;
//         }
//         const selected = new Date(selectedDate);
//         handleWeeklyQueues(selected);
//     }, [selectedDate]);


//     useEffect(() => {
//         if (graphData.length >= 4) {
//             return;
//         }
//         if (graphData.length === 0) return;

//         const count = graphData.length;
//         const today = new Date();
//         const lastWeek = new Date(today);
//         lastWeek.setDate(today.getDate() - 7);
//         const lastYear = new Date(today);
//         lastYear.setFullYear(today.getFullYear() - 1);

//         const todayFormatted = today.toLocaleDateString("en-CA", {
//             year: "numeric",
//             month: "2-digit",
//             day: "2-digit",
//         });
//         const lastWeekFormatted = lastWeek.toLocaleDateString("en-CA", {
//             year: "numeric",
//             month: "2-digit",
//             day: "2-digit",
//         });
//         const lastYearFormatted = lastYear.toLocaleDateString("en-CA", {
//             year: "numeric",
//             month: "2-digit",
//             day: "2-digit",
//         });

//         setData({
//             labels: dateLabels,
//             datasets: graphData.map((data, index) => {
//                 let labelTitle = `Week of ${data.queue[0].x}`;
//                 let borderDash = [10, 10];

//                 const includesToday = data.queue.some((item) =>
//                     item.x.split(" ")[0].includes(todayFormatted)
//                 );
//                 const includesLastWeek = data.queue.some((item) =>
//                     item.x.split(" ")[0].includes(lastWeekFormatted)
//                 );
//                 const includesLastYear = data.queue.some((item) =>
//                     item.x.split(" ")[0].includes(lastYearFormatted)
//                 );

//                 if (includesToday) {
//                     labelTitle = "This week";
//                     borderDash = [0, 0];
//                 } else if (includesLastWeek) {
//                     labelTitle = "Last week";
//                     borderDash = [5, 5];
//                 } else if (includesLastYear) {
//                     var sundayDate = data.queue[0].x;
//                     labelTitle = `Last year ${sundayDate}`;
//                 }

//                 return {
//                     label: labelTitle,
//                     data: data.queue.map((queue) => queue.y),
//                     borderColor: graphLineColor[index % count].borderColor,
//                     backgroundColor:
//                         graphLineColor[index % count].backgroundColor,
//                     borderDash: borderDash,
//                 };
//             }),
//         });
//     }, [graphData]);

//     if (data == null) {
//         return <></>;
//     }

//     return (
//         <Container sx={{ height: "100%" }}>
//             {loading ? (
//                 <>
//                     <Skeleton
//                         variant="text"
//                         width="20%"
//                         sx={{
//                             fontSize: "1rem",
//                             display: "block",
//                             margin: "auto",
//                         }}
//                     />
//                     <Skeleton
//                         variant="text"
//                         width="40%"
//                         sx={{
//                             fontSize: "1rem",
//                             display: "block",
//                             margin: "auto",
//                         }}
//                     />
//                     <br />
//                     <Skeleton
//                         variant="rounded"
//                         width="90%"
//                         height={500}
//                         sx={{ display: "block", margin: "auto" }}
//                     />
//                 </>
//             ) : (
//                 <Line options={options} data={data} />
//             )}
//             {/* <Caldendar onDateSelect={handleDateSelect} /> //TODO: Add custom date */}
//         </Container>
//     );
// }

