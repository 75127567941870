import {
    Avatar,
    Box,
    Card,
    CardContent,
    CardHeader,
    Container,
    Grid,
    Rating,
    Typography,
} from "@mui/material";
import { homeColor } from "../../style/customColor";
import { Link } from "react-router-dom";

const reviews = [
    {
        avatarSrc: "/images/mainpage/vancouver_kbbq.png",
        title: "vancouver_kbbq",
        link: "https://www.instagram.com/vancouver_kbbq",
        date: "Mar 31, 2024",
        rating: 5,
        review: "We've been using the QueuingMe app since 2023 and couldn't be happier. It's made communicating with customers during busy times so much easier, which has been a huge hit with both our restaurant staff and our guests. Plus, being able to easily identify Korean BBQ diners has streamlined our table management significantly. Highly recommend!",
    },
    {
        avatarSrc: "/images/mainpage/yeunkyung.png",
        title: "yeunkyung_yvr",
        link: "https://www.instagram.com/yeunkyung_yvr",
        date: "Feb 24, 2024",
        rating: 5,
        review: "A server who used QueuingMe at another restaurant recommended it to us, and we've been using it since we opened. We've had tons of waiting customers, and QueuingMe has been a lifesaver. Compared to other apps, it's the best value for money.",
    },
];

export default function ReviewPage() {
    return (
        <Container maxWidth={false}>
            <Grid container spacing={6} padding={4}>
                {reviews.map((review, idx) => (
                    <Grid item xs={12} md={6} key={idx}>
                        <Card
                            sx={{
                                display: "flex",
                                flexDirection: "column",
                                borderRadius: { xs: "2rem", md: "4rem" },
                                mb: { xs: 0, md: "4rem" },
                                padding: { md: "1rem" },
                            }}
                        >
                            <Link
                                to={review.link}
                                target="_blank"
                                rel="noopener noreferrer"
                                style={{
                                    textDecoration: "none",
                                    color: "inherit",
                                }}
                            >
                                <CardHeader
                                    avatar={
                                        <Avatar
                                            src={review.avatarSrc}
                                            sx={{
                                                width: 50,
                                                height: 50,
                                                margin: "0 auto",
                                            }}
                                        />
                                    }
                                    title={
                                        <Typography
                                            variant="h6"
                                            fontWeight="bold"
                                            sx={{ mb: -1 }}
                                        >
                                            {review.title}
                                        </Typography>
                                    }
                                    subheader={
                                        <Rating
                                            name={`rating-${idx}`}
                                            value={review.rating}
                                            readOnly
                                            size="small"
                                            sx={{
                                                marginTop: 1,
                                                alignSelf: "center",
                                                color: homeColor.star,
                                            }}
                                        />
                                    }
                                />
                            </Link>
                            <CardContent sx={{ flexGrow: 1 }}>
                                <Typography variant="body1">
                                    {review.review}
                                </Typography>
                                <Typography
                                    variant="body2"
                                    color="text.secondary"
                                    sx={{ mt: 2 }}
                                >
                                    {review.date}
                                </Typography>
                            </CardContent>
                        </Card>
                    </Grid>
                ))}
            </Grid>
        </Container>
    );
}
