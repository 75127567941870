export const oddRows = "WhiteSmoke";
export const customLightBlue = "#1976D2";
export const tableHeader = "#E5F6FD";

export const dashBoardTableHeader = "black";
export const dashBoardTableHeaderFont = "white";
export const lightRed = "#F05650";

//TODO: not related table.. needs to be refactored
export const primaryColor = "#1976D2";
export const successColor = "#2e7d32";

export const cobaltBlue = "#0047AB";
export const homeColor = {
    background_cobaltBlue: "#0047AB",
    card: "#CCE5FF",
    star: "#FFA534",
    success: "#198754",
};