import { LoadingButton } from "@mui/lab";
import { Box, TextField } from "@mui/material";
import { useEffect, useState } from "react";
import { useForm, FieldValues } from "react-hook-form";
import { useLocation } from "react-router-dom";
import agent from "../../../app/api/agent";
import LoadingComponent from "../../../app/layout/LoadingComponent";

export default function PasswordResetPage() {
    const [isLoading, setIsLoading] = useState(false);
    const location = useLocation();
    const [userId, setUserId] = useState<string | null>(null);
    const [token, setToken] = useState<string | null>(null);

    useEffect(() => {
        const searchParams = new URLSearchParams(location.search);
        const userIdParam = searchParams.get("userId");
        const tokenParam = searchParams.get("token");
        if (userIdParam) {
            setUserId(userIdParam);
            console.log(userId);
        }
        if (tokenParam) {
            setToken(tokenParam);
            console.log(token);
        }
    }, [location]);

    const {
        register,
        handleSubmit,
        formState: { errors, isValid, isSubmitting },
    } = useForm({
        mode: "onTouched",
    });
    async function submitForm(data: FieldValues) {
        setIsLoading(true);
        try {
            await agent.PasswordReset.resetPassword(data);
        } catch (error) {
            console.error(error);
        } finally {
            setIsLoading(false);
        }
    }

    if (!userId && !token) return <LoadingComponent message="Loading..." />;

    return (
        <>
            <Box
                component="form"
                onSubmit={handleSubmit(submitForm)}
                noValidate
                sx={{ mt: 1 }}
            >
                <div style={{ height: "0px", overflow: "hidden" }}>
                    <TextField value={userId} {...register("userId")} />
                    <TextField value={token} {...register("token")} />
                </div>
                <TextField
                    margin="normal"
                    required
                    fullWidth
                    label="New Password"
                    type="password"
                    {...register("newPassword", {
                        required: "Password is required",
                    })}
                    error={!!errors.password}
                    helperText={errors?.password?.message as string}
                />
                <LoadingButton
                    disabled={!isValid}
                    loading={isSubmitting}
                    type="submit"
                    fullWidth
                    variant="contained"
                    sx={{ mt: 3, mb: 2 }}
                >
                    Update Password
                </LoadingButton>
            </Box>
        </>
    );
}
