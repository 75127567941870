import { Container, Grid, Typography, Divider, Link } from "@mui/material";
import { primaryColor } from "../../../app/style/customColor";
import RestaurantIcon from "@mui/icons-material/Restaurant";
import { useAppSelector } from "../../../app/store/configureStore";
import { displayPhoneNumber } from "../../../app/configuration/phoneNumberConvertor";
import PlaceIcon from "@mui/icons-material/Place";

//TODO: Implement Address Controller to update restaurant address or phone number
export default function RestaurantInfo() {
    const { user } = useAppSelector((state) => state.account);
    if (!user) return null;

    return (
        <Container>
            <Grid container marginTop={3}>
                <RestaurantIcon fontSize="large" color="primary" />
                <Typography
                    variant="h5"
                    fontWeight="bold"
                    color={primaryColor}
                    sx={{ marginLeft: 1 }}
                >
                    Restaurant
                </Typography>
            </Grid>
            <Divider />
            {user?.restaurants?.map((restaurant) => {
                return (
                    <>
                        <Grid
                            container
                            spacing={2}
                            key={restaurant.id}
                            margin={2}
                        >
                            <Grid
                                item
                                xs={12}
                                md={2}
                                sx={{ display: "flex", alignItems: "center" }}
                            >
                                <img
                                    src={`/images/${restaurant.restaurantName}Icon.png`}
                                    style={{
                                        width: "100px",
                                    }}
                                    alt="logo"
                                />
                            </Grid>

                            <Grid item xs={12} md={10}>
                                <Typography variant="h6">
                                    {restaurant.restaurantName} &nbsp;&nbsp;
                                    <Link
                                        component="button"
                                        color="inherit"
                                        href={"tel:" + restaurant.phoneNumber}
                                    >
                                        <Typography variant="body1">
                                            {displayPhoneNumber(
                                                restaurant.phoneNumber
                                            )}
                                        </Typography>
                                    </Link>
                                </Typography>
                                <br />
                                <Link
                                    color="inherit"
                                    href={
                                        "https://www.google.com/maps/search/?api=1&query=" +
                                        restaurant.restaurantName
                                    }
                                    target="blank"
                                >
                                    <Typography
                                        variant="subtitle2"
                                        sx={{
                                            display: "flex",
                                            alignItems: "center",
                                        }}
                                    >
                                        <PlaceIcon />
                                        {`${restaurant.address?.street}, 
                                        ${restaurant.address?.city}, 
                                        ${restaurant.address?.province} 
                                        ${restaurant.address?.postalCode}`}
                                    </Typography>
                                </Link>
                            </Grid>
                        </Grid>
                        <Divider />
                    </>
                );
            })}
        </Container>
    );
}
