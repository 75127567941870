import { Container, Grid, Typography, Divider } from "@mui/material";
import { primaryColor } from "../../../app/style/customColor";
import AutoStoriesIcon from "@mui/icons-material/AutoStories";
import SolutionPage from "../../../app/layout/HomePage/SolutionPage";

export default function Application() {
    return (
        <Container>
            <Grid container marginTop={3}>
                <AutoStoriesIcon fontSize="large" color="primary" />
                <Typography
                    variant="h5"
                    fontWeight="bold"
                    color={primaryColor}
                    sx={{ marginLeft: 1 }}
                >
                    Application
                </Typography>
            </Grid>
            <Divider />
            <Grid container sx={{marginTop: {lg: -10}}}>
            <SolutionPage/>
            </Grid>
        </Container>
    );
}
