import React from 'react';

interface WithRotationProps {
  [key: string]: any;
}

const withRotation = <P extends object>(WrappedComponent: React.ComponentType<P>) => {
  return (props: WithRotationProps) => {
    const queryParams = new URLSearchParams(window.location.search);
    const rotate = queryParams.has('rotate');

    const rotationStyle: React.CSSProperties = {
      transform: rotate ? 'rotate(270deg)' : 'none',
      width: rotate ? '100vh' : `100%`,
      height: rotate ? '100vh' : `100%`,
      position: rotate ? 'fixed' : 'relative',
      top: 0,
      left: 0,
    };

    const contentStyle: React.CSSProperties = {
      display: 'block',
      justifyContent: 'center',
      width: '100%',
      height: '100%',
    };

    return (
      <div style={rotationStyle}>
        <div style={contentStyle}>
          <WrappedComponent {...props as P} />
        </div>
      </div>
    );
  };
};

export default withRotation;
