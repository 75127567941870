export const configTime = {
    noShowTime: 5,
};

export const configMiliTime = {
    refreshDisplay: 5000,
    refreshAdmin: 60000,
};

export const waitingWarningTime = 30;
